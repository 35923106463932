import React from "react";
import { SVG, selectIcon } from "../../../components/UI/selector";

const Pagination = ({ userArrLength, limit, currentPage, setPage }) => {
    const total = checkTotalPage(userArrLength,limit);
    return (
        <div className='list_button_container'>
            <ListButton bottom={false} active={currentPage > 1 && total > 1} click={() => setPage(currentPage-1)} />
            <ListButton bottom={true} active={currentPage < total && total > 1} click={() => setPage(currentPage+1)} />
        </div>
    )    
}

export default Pagination;
const checkTotalPage = (userArrLength,limit) => {
    let currentPage = parseInt(userArrLength / limit,10);
    if(userArrLength % limit !== 0) {
        currentPage++;
    }
    return currentPage;
}

const ListButton = ({active,bottom,click,...props}) => (
    <div className={`listButton${bottom?' bottom':''}${active?' active':''}`} onClick={active?click:null}>
        <SVG src={selectIcon('arrowIcon')} />
    </div>
)