import React, { Component } from 'react';
import IO from 'socket.io-client/dist/socket.io.js';
import QRCode from "react-qr-code";
import './Home.scss';
import videoThumbnail from '../../assets/videos/videoThumbnail1.png';
import { CheckValue, env, message } from '../../services/CommonFunction';
import { SVG, selectIcon } from '../../components/UI/selector';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlay, faRedo } from '@fortawesome/free-solid-svg-icons';
import { staticVar, themeData } from '../../services/Constants';


class Home extends Component {

    state = {
        QRCodeID: '',
        video: null,
        isVideoPlaying: false,
        isVideoEnded: false,
        socket: IO(env('OnDemandSocket')+env('SocketToken'), { transports: ['websocket'] }),
        // socket: IO(env('OnDemandSocket') + 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzZXNzaW9uX2lkIjoiYzk1MzQ1MDUtODNjMi00MTIzLWJlNjQtY2RhMWQ2MjlkNTNkIiwiZGF0ZSI6IjIwMjEtMDUtMjJUMDU6MDk6MzUuOTYzWiIsImlhdCI6MTYyMTY2MDE3NX0.8sfzRwmOcFtnC-LPwAEtB4I7_paWALA3k6ALmRQFjAQ', { transports: ['websocket'] }),
        workoutData: {},
        screenWidth: 250,
        // storage: ['videoPausedOnce','messageCount','workoutData','accessToken','ondemandData','startTime','isCameraEnabled','OvCameraId','OvAudioId','setVolume']
    }

    async componentDidMount() {
        message.list = [];
        message.count = 0;
        if (this.props.start) {
            this.socketListner('on');
        }
        Object.keys(window.localStorage).forEach(el => {
            if( el !== 'local' && el !== 'OnDemandSocket' && el !== 'AntMediaUrl' && el !== 'SocketToken') {
                localStorage.removeItem(el);
            }
        })
        if (this.props.match.params.appId && themeData.hasOwnProperty(this.props.match.params.appId)) {
            const appId = this.props.match.params.appId;
            localStorage.setItem('appId', appId);
        }
        this.setState({
            screenWidth: this.setQRcodeWidth(window.innerWidth)
        })

        this.eventListener('addEventListener');
    }

    socketListner = (status) => {
        this.state.socket[status]('connect', this.socketConnected)
        this.state.socket[status]("sync_workout_request", this.syncWorkout);
    }

    eventListener = (status) => {
        // window[status]('orientationchange', function () {
        //     var originalBodyStyle = getComputedStyle(document.body).getPropertyValue('orientation');
        // });
        window[status]('resize', this.handleResize)
    }

    componentWillUnmount() {
        this.eventListener('removeEventListener');
        this.state.socket.disconnect();
        this.socketListner('off');
    }

    handleResize = () => {
        this.setState({
            screenWidth: this.setQRcodeWidth(window.innerWidth)
        })
    }


    setQRcodeWidth = (width) => {
        switch (true) {
            case (width >= 1224 && width < 1501):
                return 230;
            case (width >= 1024 && width < 1224):
                return 210;
            case (width >= 768 && width < 1024):
                return 170;
            case (width >= 480 && width < 768):
                return 250;
            case (width >= 320 && width < 480):
                return 230;
            default:
                return 250;
        }
    }

    syncWorkout = (data, access_token) => {
        if (Object.keys(data).length) {
            if (data.is_ondemand_workout || data.activity_status === 'STARTED' || data.activity_status === 'IN_WAITING_ROOM') {
                if (CheckValue(access_token.app_id) && access_token.app_id.length) {
                    const temp = env('checkAppId');
                    if (CheckValue(temp[access_token.app_id])) {
                        localStorage.setItem('appId', temp[access_token.app_id]);
                    }
                }
                localStorage.setItem('workoutData', JSON.stringify(data));
                localStorage.setItem('ondemandData', JSON.stringify(access_token));
                localStorage.setItem('accessToken', access_token.access_token);
                this.props.history.push('/');
            }
        }
    }

    socketConnected = () => {
        console.info("connected", this.state.socket.id);
        this.setState({
            QRCodeID: this.state.socket.id
        });
    }

    playVideo = () => {
        if (!this.state.isVideoPlaying) {
            this.setState({
                isVideoPlaying: true
            });
            document.getElementById('login-video').play();
            // this.setState({ video: document.getElementById('login-video') });
            // setTimeout(() => {
            //     this.state.video.play();
            // }, 1000);
        }
    }

    videoEnded = () => {
        this.setState({
            isVideoPlaying: false,
            isVideoEnded: true
        });
    }

    render() {
        const videoLink = "https://tribe-website-videos.s3.amazonaws.com/newHomePage.mp4";
        return (
            <section className="Home">
                <div className="containerDiv mainPage">
                    <div className="containerBox">
                        <div className='containerBox_innerBox'>
                            <div className='leftDiv'>
                                <h2>Cast your class here</h2>
                                <div className='stepDiv'>
                                    <p>1. Open your fitness app on the phone</p>
                                    <p>2. Join the class</p>
                                    <p className="imagesIconsClass">3. Tap
                                        <span className='icon_div'>
                                            <SVG className="scanIcon" src={selectIcon('castIcon')} />
                                        </span>
                                        on your ongoing class screen</p>
                                    <p>4. Select
                                        <span className='icon_div'>
                                            <SVG className="scanIcon" src={selectIcon('screenIcon')} />
                                        </span>
                                        Cast to Web</p>
                                    <p>5. Use your phone to scan this QR code</p>

                                </div>
                                <div className="videoContainer">
                                    <div className="videoTagDiv" onClick={this.playVideo}
                                        style={{ cursor: !this.state.isVideoPlaying ? 'pointer' : 'unset' }}>
                                        {!this.state.isVideoPlaying ?
                                            <React.Fragment>
                                                <div className='playIcon_div'></div>
                                                <div className="playIcon">
                                                    <FontAwesomeIcon style={{ color: '#F22B5D' }}
                                                        icon={this.state.isVideoEnded ? faRedo : faPlay} />
                                                </div>
                                                <img className="videoImage preview" src={videoThumbnail} alt='preview' />
                                            </React.Fragment> :
                                            null
                                        }
                                        <video id='login-video' preload="auto" onEnded={this.videoEnded} className={`videoImage${this.state.isVideoPlaying ? '' : ' hide'}`} controls src={videoLink} type="video/mp4" />
                                    </div>
                                    {/* <p>Need help to get started?</p> */}
                                </div>
                            </div>
                            <div className='rightDiv'>
                                <div className="scan">
                                    <QRCode value={this.state.QRCodeID + '_tribeWeb'} size={this.state.screenWidth} />
                                </div>
                                <p className='scanCode'>Scan QR code to cast here</p>
                            </div>
                        </div>
                        <div className='footer_div1'></div>
                        <div className='footer_div2'></div>
                    </div>
                    <div className='version'>version-{staticVar.version}</div>
                </div>
            </section>

        );
    }
}

export default Home;