import React, { Suspense, useEffect, useState } from 'react';
import { Route, Switch } from 'react-router-dom';
import Spinner from './components/UI/Loader/Loader';

import Home from './containers/Home/Home';
import Layout from './hoc/Layout/Layout';
import InternalLayout from './hoc/InternalLayout/InternalLayout';
import ProtectedRoute from './services/ProtectedRoute';
import Modals, { ErrorUI } from './components/UI/Modals/Modals';
import { browserName } from 'react-device-detect';
import LocalURL from './containers/LocalURL/Local';
import { staticVar } from './services/Constants';
import { CheckValue } from './services/CommonFunction';

// const LocalURL = React.lazy(() => import('./containers/LocalURL/Local')) ;

const App = () => {
  const [start] = useState(browserName !== "Safari" && browserName !== "Firefox");
  useEffect(() => {
    console.info("version - ", staticVar.version);
    console.info("Enter");

    const eventListener = (status) => {
      document[status]("visibilitychange", () => {
        if (document.hidden) {
          localStorage.setItem('hidden',+new Date());
           // tab is changed
        } else {
          const hiddenTime = localStorage.getItem('hidden');
          if(CheckValue(hiddenTime)) {
            localStorage.removeItem('hidden');
            const currentTime = +new Date();
            if((currentTime - Number(hiddenTime)) >= (1000 * 60 * 60 * 1)) { // milisecond * second * minute * hour
              window.location.reload(true);
            }
          }        
           // tab is active
        }
      });  
    }
    eventListener('addEventListener');
    return () => {
      eventListener('removeEventListener');
    }
  },[]);
  useEffect(() => {
    if (!start) {
      ErrorUI("We currently do not support this browser. Please use Chrome.");
      return;
    }
  },[start]);

  return (
    <div>
    <Modals />
    <Suspense fallback={<div>Loading...</div>}>
      <Layout>
        <Switch>
          <Route path="/Home/:appId?" exact render={(props) => <Home start={start} {...props} />} />
          <Route exact path="/localUrl" component={LocalURL} />
          <ProtectedRoute path="/" component={InternalLayout} />
          <Route path="*" component={() => (<h1>404 Not Fount !</h1>)} />

        </Switch>
      </Layout>
    </Suspense>
    {/* ==========PAGE LOADER============== */}

    <Spinner />

    {/* =================================== */}
  </div>
  )
}

export default App;
