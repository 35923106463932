import React, { Component } from "react";
import { RotatingLines, ThreeDots } from "react-loader-spinner";
import { randomString } from "../../../services/CommonFunction";
import { messageService } from "../../../services/subscriberService";
import "./Loader.scss";
import Variables from "../../../Sass/Variables.scss";
class Spinner extends Component {
  state = {
    show: false,
    background: false,
  };

  componentDidMount() {
    this.subscription = messageService.getMessage().subscribe((data) => {
      if (data) {
        this.setState({
          background: messageService.background,
          show: data.show,
        });
      }
    });
  }

  render() {
    const { show, background } = this.state;
    const loader = (
      <ThreeDots
        key="loader"
        className="loader"
        visible={this.state.show}
        color={Variables.primaryColor1}
        height={50}
        width={100}
      />
    );
    return (
      <React.Fragment>
        {show ? (
          <div className={`backgroundC${background ? " show" : ""}`}>
            {messageService.text}
            {loader}
            {/* <Loader className = "loader"
                        visible={true}
                        type="ThreeDots"
                        color="#F22B5D"
                        height={100}
                        width={100}
                    /> */}
          </div>
        ) : null}
      </React.Fragment>
    );
  }
}

export default Spinner;

export const SmallLoader = ({ clas, show, ...props }) => (
  <React.Fragment>
    {show ? (
      <div className="backgroundC smallLoader">
        <RotatingLines
          className={clas || "loader"}
          visible={show}
          key={randomString(10)}
          strokeColor="grey"
          strokeWidth="3"
          animationDuration="0.5"
          width="50"
        />
      </div>
    ) : null}
  </React.Fragment>
);
