import React, { Component } from 'react';
import './Modals.scss';
import { modalService, modalPopup, logOutService, logOut } from '../../../services/subscriberService';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { Button } from '../Button/Button';
import { CheckValue } from '../../../services/CommonFunction';

class Modals extends Component {

    state = {
        status: false,
        data: null
    }

    componentDidMount() {
        this.modalSubscriber = modalService.getModalMessage().subscribe(res => {
            this.setState({
                data: res.data,
                status: res.status
            })
        });
    }

    componentWillUnmount() {
        this.modalSubscriber.unsubscribe();
    }

    render() {
        const status = this.state.status ?
            <div className="Backdrop zIndex">
                <div className="historyModals">
                    <div className="modal_div">
                        {modalPopup.cross && <div className="oval" onClick={() => {
                                logOut.setLogOut(false);
                                ClosePopup();
                            }}>
                            <FontAwesomeIcon icon={faTimes} />
                        </div>}
                        {this.state.data}
                    </div>
                </div>
            </div> :
            null;

        return (
            <React.Fragment>
                {status}
            </React.Fragment>
        )
    }
}

export const ClosePopup = () => {
    modalService.sendModalMessage({ status: false, data: null });
    modalPopup.cross = true;
}

const OpenPopup = (data, showCross = modalPopup.cross) => {
    if (CheckValue(data.props.videoSrc)) {
        modalPopup.cross = true;
    } else {
        modalPopup.cross = showCross;
    }
    modalService.sendModalMessage({ status: true, data: data });
}

const ErrorModals = (props) => {
    let close = () => props.close ? props.close() : null;
    let cancel = true;
    let cancelText = props.cancelText || "Ok";
    let cancelClass = props.cancelClass || "btns-defaults";
    let retry = props.retry;
    let msgs = props.text;
    let statusCode = 0;
    if (typeof props.text === 'string') {
        if (props.text === 'Try Again' || props.text === 'Connection terminated due to connection timeout') {
            cancel = false;
        }
    } else if (typeof props.text === 'object') {
        if (props.text.data === undefined && props.text.message === undefined) {
            cancel = false;
            msgs = 'Try Again';
        } else {
            msgs = props.text.message || props.text.data.message || 'Try Again';
        }
        statusCode = props.text.statusCode || props.text.data.statusCode || 0;
    } else if (!CheckValue(props.text)) {
        cancel = false;
        msgs = 'Try Again';
    }

    if (!retry) {
        cancelClass = props.cancelClass || 'btns-primary';
        cancel = true;
        cancelText = props.cancelText || "Ok";
    }
    if (statusCode === 401) {
        retry = false;
        cancelClass = 'btns-primary';
        cancelText = 'Ok';
        close = () => {
            logOutService.sendMessage('logout');
            logOutService.sendStopMessage('logout');
        };
    }

    return (
        <MainDivModal clas="errorModalUI">
            <div className="body">
                <span className="text">{msgs}</span>
                {props.note ? <span className="note">{props.note}</span> : null}
            </div>
            {/* <span className="text">Please enter the class duration to schedule without segments.</span> */}
            <div className="footer">
                {retry ? <Button label={props.submitText || "Retry"} className="btns-primary" handleClick={() => {
                    modalPopup.cross = true;
                    ClosePopup(true);
                    props.retryData();
                }} /> : null}
                {cancel ? <Button label={cancelText} className={cancelClass} handleClick={() => {
                    modalPopup.cross = true;
                    close();
                    ClosePopup(true);
                }} /> : null}
            </div>
        </MainDivModal>
    )
}

export const ErrorUI = (msg, fun = null, data = {}) => {
    if (data.cross) {
        modalPopup.cross = data.cross;
    } else {
        modalPopup.cross = false;
    }
    OpenPopup(<ErrorModals text={msg} {...data} retry={fun !== null} retryData={() => {
        fun();
    }} />)
}


export const MainDivModal = (props) => <div className={props.clas || 'durationModal'}>{props.children}</div>

export default Modals;