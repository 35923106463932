import React, { Component } from "react";
import "../NewCasting/Ongoing/Ongoing.scss";
import IO from "socket.io-client/dist/socket.io.js";
import {
  caloriesSort,
  CheckAccess,
  CheckValue,
  env,
  getOndemandAvailableUser,
  setGym,
  setUserInOndemandActivity,
  setWorkoutBuilder,
} from "../../services/CommonFunction";
import axios from "../../axios";
import { throttle } from "lodash";
import { colorSelector } from "../../components/UI/selector";
import ApiURL from "../../services/ApiURL";
import {
  seekVideoSubscriber,
  seekCompleteSubscriber,
  bufferingSubscriber,
  playerPlaySubscriber,
} from "../../services/subscriberService";
import StreamMember from "../NewCasting/StreamMember/StreamMember";
import {
  BoardContainerOndemandRoom,
  FooterOngoing,
  PinVideoView,
} from "../NewCasting/ChildComponentUI/ChildComponentUI";
import FullScreen from "../../components/UI/FullScreen/FullScreen";
import { activityType1 } from "../../services/Constants";
import { ErrorUI } from "../../components/UI/Modals/Modals";
import { changeVolume } from "../../components/UI/InputRange/InputRange";
import { Header } from "../NewCasting/Header/Header";

class Ondemand extends Component {
  constructor(props) {
    super(props);
    this.memberVideo = React.createRef();
    this.timer = null;
    this.myInterval = null;
    this.pollingInterval = null;
    const ondemandData = {
      ...JSON.parse(localStorage.getItem("ondemandData")),
    };
    const workoutData = { ...JSON.parse(localStorage.getItem("workoutData")) };
    localStorage.setItem("audioNow", true);
    localStorage.setItem("videoNow", true);

    let segmentDurations = [];
    // if(!CheckValue(workoutData.workout_builder)) {
    //     workoutData.workout_builder = workoutData1.workout_builder;
    // }
    if (!CheckValue(localStorage.getItem("firstTime"))) {
      if (CheckValue(localStorage.getItem("videoPaused"))) {
        ondemandData.status = "PAUSE";
      } else {
        ondemandData.status = "PLAY";
      }
    } else {
      localStorage.removeItem("firstTime");
    }
    if (workoutData.workout_builder && workoutData.workout_builder.length) {
      workoutData["total_activity_duration_in_seconds"] =
        ondemandData.duration_in_ms / 1000;
      setWorkoutBuilder(workoutData);
      segmentDurations = [...workoutData.workout_builder];
    }
    this.state = {
      id: "video-player",
      value: 100,
      STEP: 1,
      MIN: 0,
      MAX: 0,
      liveLatency: 0,
      gymData: setGym(workoutData),
      isInternetConnected: null,
      required: {
        isHeartRateRequired: workoutData.heartrate_required,
        isLeaderBoardRequired: workoutData.leaderboard_type,
      },
      disconnectTime: 0,
      workoutData: { ...workoutData },
      ondemandData: { ...ondemandData },
      onDemandControls: false,
      playerBuffering: false,
      totalMembers: 0,
      totalSeconds: 0,
      vid: null,
      playerPlayStatus: ondemandData.status === "PLAY",
      // fullScreen: false,
      toggleDiv: {
        list: false,
        stats: false,
        video: false,
      },
      firstTaggedEmit: false,
      friendsWidth: 300,
      mute: false,
      boardWidth: 350,
      new_user: {},
      values: [1],
      selfData: {},
      usersInActivity: [],
      clubbedUsers: [],
      segmentDurations: [],
      seg_total_width: "0%",
      seg_total_duration: 0,
      showSeekBar: false,
      is_WorkoutBuilder_Available: false,
      actionPerform: true,
      cur_segment_data: {},
      cur_exercise_data: {},
      segment_duration_max: null,
      segment_duration_prev_max: null,
      socket: IO(env("OnDemandSocket") + localStorage.getItem("accessToken"), {
        transports: ["websocket"],
      }),
      userActivityData: {},
      allUsers: {},
      tagUsers: [],
      ongoing: {
        ivsStatus: true,
        playBackURL: CheckValue(workoutData.playback_url)
          ? workoutData.playback_url
          : workoutData.ondemand_videos[workoutData.ondemand_videos.length - 1],
        segmentDurations: segmentDurations,
        started_at: undefined,
        start_datetime: undefined,
        total_activity_duration_in_seconds: ondemandData.duration_in_ms / 1000,
      },
    };
  }

  componentDidMount = async () => {
    let startTime = 0;
    const currentTime = Math.round(new Date().getTime() / 1000);
    const { ondemandData, id } = this.state;
    // ErrorUI(staticVar.textMsg, null, {
    //     close: () => {
    //         this.setState({ actionPerform: true});
    //     }
    // });
    if (localStorage.getItem("startTime")) {
      startTime = parseInt(localStorage.getItem("startTime"));
    } else {
      startTime = Math.round(
        (new Date().getTime() - ondemandData.time_elapsed * 1000) / 1000
      );
      localStorage.setItem("startTime", startTime);
    }
    this.state.socket.on("connect", this.socket_connected);
    this.state.socket.on("disconnect", this.socket_disconnected);

    await this.setState({
      totalSeconds: localStorage.getItem("currentTime")
        ? parseInt(localStorage.getItem("currentTime"))
        : ondemandData.time_elapsed
        ? Math.round(currentTime) - Math.round(startTime) + 1
        : 0,
      values: [
        Math.round(
          ondemandData.time_elapsed
            ? Math.round(currentTime) - Math.round(startTime) + 1
            : 0
        ),
      ],
      vid: document.getElementById(id),
    });

    if (CheckValue(localStorage.getItem("setVolume"))) {
      changeVolume(
        parseInt(localStorage.getItem("setVolume"), 10),
        this.state.id,
        "null"
      );
    } else {
      changeVolume(100, this.state.id, "null");
    }

    setTimeout(() => {
      this.getData();
    }, 1000);

    this.mouseMoved = throttle(this.mouseMoved, 3000);
    this.updateSegmentArray = throttle(this.updateSegmentArray, 200);

    this.subscribeFun();
  };

  subscribeFun = () => {
    let isSegmentSet = false;
    const { workoutData, ondemandData } = this.state;
    this.seekCompleteSubscription = seekCompleteSubscriber
      .get()
      .subscribe((data) => {
        if (this.state.vid.duration !== "NaN" && !isSegmentSet) {
          if (CheckValue(localStorage.getItem("videoPaused"))) {
            playerPlaySubscriber.send(false);
            this.setState({
              playerPlayStatus: false,
            });
            this.playPausePlayer(false, "mediaAudio");
            this.playPausePlayer(false, "mediaVideo");
          } else if (ondemandData.status === "PAUSE") {
            localStorage.setItem("videoPaused", "true1");
            this.videoControl("pause");
          }
          if (
            workoutData.workout_builder &&
            workoutData.workout_builder.length
          ) {
            const videoMax = Math.round(this.state.vid.duration);
            this.setState({
              MAX: videoMax,
              is_WorkoutBuilder_Available: true,
              // showSeekBar: true,
            });
            this.setSegmentData(
              [...workoutData.workout_builder],
              Math.round(this.state.vid.duration)
            );
          } else {
            this.setState({
              is_WorkoutBuilder_Available: false,
              MAX: Math.round(this.state.vid.duration),
              seg_total_width: "100",
              seg_total_duration: Math.round(this.state.vid.duration),
              showSeekBar: true,
            });
          }
          isSegmentSet = true;
        }
        if (this.state.playerBuffering) {
          //consolele.log("Timer Started");
          clearInterval(this.myInterval);
          this.startTimer();
          this.setState({
            playerBuffering: false,
          });
        }
        if (this.state.totalSeconds !== data.data) {
          this.setState({
            totalSeconds: data.data,
          });
        }
      });
    this.bufferingSubscription = bufferingSubscriber.get().subscribe((data) => {
      if (Math.round(this.state.vid.duration) - this.state.totalSeconds === 2) {
        this.state.vid.pause();
        clearInterval(this.myInterval);
        this.stopActivity();
      }
      // clearInterval(this.myInterval);
      this.setState({
        playerBuffering: true,
      });
    });
  };

  setSegmentData = (workout_builder, workout_duration) => {
    if (this.state.is_WorkoutBuilder_Available) {
      let seg_total_width = 0;
      let seg_total_duration = 0;
      let seg_prev_total_duration = 0;
      workout_builder.forEach((el) => {
        el["segment_duration_in_seconds"] = el.segment_duration_in_seconds
          ? el.segment_duration_in_seconds
          : el.segment_duration_in_ms;
        seg_total_width =
          Math.round(
            (el.segment_duration_in_seconds / workout_duration) * 100
          ) + seg_total_width;
        if (seg_total_duration) {
          seg_prev_total_duration = seg_total_duration;
        }
        seg_total_duration =
          el.segment_duration_in_seconds + seg_total_duration;
        if (seg_total_duration < workout_duration) {
          el["width"] =
            Math.round(
              (el.segment_duration_in_seconds / workout_duration) * 100
            ) + "%";
        } else if (workout_duration > seg_prev_total_duration) {
          el["width"] =
            Math.round(
              ((workout_duration - seg_prev_total_duration) /
                workout_duration) *
                100
            ) + "%";
          el["segment_duration_in_seconds"] =
            workout_duration - seg_prev_total_duration;
        } else {
          el["width"] = 0;
          seg_total_duration =
            seg_total_duration - el.segment_duration_in_seconds;
        }
      });
      this.updateSegmentArray(
        [...workout_builder.filter((el) => el.width)],
        this.state.totalSeconds
      );

      this.setState({
        seg_total_width:
          seg_total_duration <= workout_duration ? seg_total_width : "100",
        seg_total_duration: seg_total_duration,
      });
      setTimeout(() => {
        this.setState({
          showSeekBar: true,
        });
      }, 100);
    }
  };

  updateSegmentArray = (segment_array, totalSeconds) => {
    if (this.state.is_WorkoutBuilder_Available && segment_array.length) {
      let prevMax = 0;
      let segment_duration_max = null;
      let segment_duration_prev_max = null;

      if (true) {
        segment_array.forEach((el) => {
          let prevExerciseMax = null;
          let ex_total_duration = 0;
          let ex_prev_total_duration = 0;
          el.exercises.forEach((ed) => {
            ex_prev_total_duration = ex_total_duration;
            ex_total_duration =
              ed.exercise_duration_in_seconds + ex_total_duration;
            if (ex_total_duration < el.segment_duration_in_seconds) {
              ed["exercise_duration_in_seconds"] =
                ed.exercise_duration_in_seconds;
            } else if (
              el.segment_duration_in_seconds > ex_prev_total_duration
            ) {
              ed["exercise_duration_in_seconds"] =
                el.segment_duration_in_seconds - ex_prev_total_duration;
            } else {
              ed["exercise_duration_in_seconds"] = 0;
            }
            if (prevExerciseMax === null) {
              ed["combinedExerciseMax"] =
                ed.exercise_duration_in_seconds + prevMax;
              prevExerciseMax = ed.exercise_duration_in_seconds + prevMax;
            } else {
              ed["combinedExerciseMax"] =
                ed.exercise_duration_in_seconds + prevExerciseMax;
              prevExerciseMax =
                ed.exercise_duration_in_seconds + prevExerciseMax;
            }
          });
          el["combinedMax"] = el.segment_duration_in_seconds + prevMax;
          prevMax = el.segment_duration_in_seconds + prevMax;
          if (totalSeconds <= el.combinedMax && segment_duration_max === null) {
            el["values"] = [
              segment_duration_prev_max === null
                ? totalSeconds
                : totalSeconds -
                  (el.combinedMax - el.segment_duration_in_seconds),
            ];
            segment_duration_max = el.segment_duration_in_seconds;
            this.setState({
              segment_duration_max: el.segment_duration_in_seconds,
            });
          } else if (totalSeconds >= el.combinedMax) {
            el["values"] = [el.segment_duration_in_seconds];
            segment_duration_prev_max = el.segment_duration_in_seconds;
            this.setState({
              segment_duration_prev_max: el.segment_duration_in_seconds,
            });
          } else {
            el["values"] = [0];
          }
          el["MIN"] = 0;
          el["MAX"] = el.segment_duration_in_seconds;
          el["STEP"] = 1;
        });
        this.setState({
          segmentDurations: [...segment_array],
        });
      }
      const cur_segment_data = segment_array.find(
        (el) =>
          totalSeconds <= el.combinedMax &&
          el.combinedMax - el.segment_duration_in_seconds <= totalSeconds
      );

      const cur_exercise_data =
        cur_segment_data !== undefined && cur_segment_data.exercises.length
          ? cur_segment_data.exercises.find(
              (ed) => totalSeconds < ed.combinedExerciseMax
            )
          : {};

      this.setState({
        cur_segment_data: { ...cur_segment_data },
        cur_exercise_data: { ...cur_exercise_data },
      });
    }
  };

  // /* View in fullscreen */
  // openFullscreen = () => {
  //     var elem = document.documentElement;
  //     if (elem.requestFullscreen) {
  //         ////////////consolele.log("Hello Chrme");
  //         elem.requestFullscreen();
  //     } else if (elem.webkitRequestFullscreen) { /* Safari */
  //         ////////////consolele.log("Hello Safari");
  //         elem.webkitRequestFullscreen();
  //     } else if (elem.msRequestFullscreen) { /* IE11 */
  //         ////////////consolele.log("Hello IE11");
  //         elem.msRequestFullscreen();
  //     }
  //     this.setState({
  //         fullScreen: true
  //     })

  // }

  // /* Close fullscreen */
  // closeFullscreen = () => {
  //     if (document.exitFullscreen) {
  //         document.exitFullscreen();
  //     } else if (document.webkitExitFullscreen) { /* Safari */
  //         document.webkitExitFullscreen();
  //     } else if (document.msExitFullscreen) { /* IE11 */
  //         document.msExitFullscreen();
  //     }
  //     this.setState({
  //         fullScreen: false
  //     })
  // }

  componentWillUnmount() {
    const data = {
      activity_id: this.state.ondemandData.activity_id,
      user_activity_session_id:
        this.state.ondemandData.user_activity_session_id,
    };
    if (this.seekCompleteSubscription) {
      this.seekCompleteSubscription.unsubscribe();
    }
    if (this.bufferingSubscription) {
      this.bufferingSubscription.unsubscribe();
    }
    localStorage.removeItem("currentTime");
    clearInterval(this.pollingInterval);
    clearInterval(this.myInterval);
    this.callListners("off");
    this.state.socket.off("connect", this.socket_connected);
    this.state.socket.off("disconnect", this.socket_disconnected);
    this.state.socket.emit("unsubscribe_activity", data, (obj) => {
      //////////consolele.log(obj);
      this.state.socket.disconnect();
    });
  }

  socket_connected = () => {
    const ondemandData = {
      ...JSON.parse(localStorage.getItem("ondemandData")),
    };
    this.socketEmitHandler({
      activity_id: ondemandData.activity_id,
      user_activity_session_id: ondemandData.user_activity_session_id,
    });
  };

  socket_disconnected = (data) => {
    //////////consolele.log("Disconnected", data);
    this.setState({
      isInternetConnected: false,
      disconnectTime: this.state.totalSeconds,
    });
    clearInterval(this.pollingInterval);
  };

  socketEmitHandler = (data) => {
    this.state.socket.emit("web_workout_sync_request", data, (obj) => {
      //////////consolele.log("web_workout_sync_request", obj);
      if (
        obj.user_status_in_activity === "ENDED" ||
        obj.web_preview === "false"
      ) {
        this.end_activity();
      } else {
        this.setState({
          isInternetConnected: true,
          disconnectTime: 0,
        });
        this.activityPolling();
        if (this.state.firstTaggedEmit) {
          this.syncSocketEmitHandler({
            user_activity_session_id:
              this.state.ondemandData.user_activity_session_id,
            web_socket_id: this.state.socket.id,
          });
        }
      }
    });
  };

  syncSocketEmitHandler = (data) => {
    this.state.socket.emit("sync_tagged_videos", data, (obj) => {});
  };

  checkLength = (data) => (data < 10 ? "0" + data : data);

  stopActivity = () => {
    const obj = {
      activity_id: this.state.ondemandData.activity_id,
      user_activity_session_id:
        this.state.ondemandData.user_activity_session_id,
    };
    axios.post(ApiURL._endOndemand, obj).then((res) => {});
    const data = {
      user_activity_session_id:
        this.state.ondemandData.user_activity_session_id,
      status: "ENDED",
    };
    setTimeout(() => {
      this.state.socket.emit("ondemand_activity_sync", data, (res) => {
        this.end_activity();
      });
    }, 500);
  };

  activityPolling = () => {
    this.pollingInterval = setInterval(() => {
      const data = {
        user_activity_session_id:
          this.state.ondemandData.user_activity_session_id,
        status: "PROGRESS",
        current_duration: this.state.totalSeconds,
      };
      //////////consolele.log("Polling", data);
      this.state.socket.emit("ondemand_activity_sync", data, (res) => {
        //////////consolele.log("Polling");
      });
    }, 8000);
  };

  startTimer = () => {
    this.myInterval = setInterval(() => {
      if (!this.state.isInternetConnected) {
        //////////consolele.log("disconnect counter", this.state.totalSeconds);
        // if ((this.state.totalSeconds - this.state.disconnectTime) > 9) {
        //     //////////consolele.log("Logout", this.state.totalSeconds);
        //     this.end_activity();
        // }
      }
      const { totalSeconds } = this.state;
      if (Math.round(this.state.vid.duration) - totalSeconds === 2) {
        this.state.vid.pause();
        clearInterval(this.myInterval);
        this.stopActivity();
      } else {
        this.setState({
          totalSeconds: Math.round(this.state.vid.currentTime),
          values: [Math.round(this.state.vid.currentTime)],
        });
        if (
          localStorage.getItem("currentTime") !==
          Math.round(this.state.vid.currentTime)
        ) {
          localStorage.setItem(
            "currentTime",
            Math.round(this.state.vid.currentTime)
          );
        }
      }
      if (
        this.state.vid !== undefined &&
        this.state.mute !== this.state.vid.muted
      ) {
        this.setState({ mute: this.state.vid.muted });
      }
    }, 1000);
  };

  end_activity = (data) => {
    localStorage.removeItem("ondemandData");
    localStorage.removeItem("startTime");
    localStorage.removeItem("workoutData");
    localStorage.removeItem("accessToken");
    localStorage.removeItem("videoPausedOnce");
    localStorage.removeItem("currentTime");
    this.props.history.push("/Home");
  };

  self_heartbeat = (data) => {
    if (this.state.playerPlayStatus) {
      const { allUsers, usersInActivity, tagUsers } = this.state;
      usersInActivity
        .filter(
          (el) => el.user_activity_session_id === data.user_activity_session_id
        )
        .forEach((el) => {
          el.calories_burnt = data.calories_burnt
            ? Math.round(data.calories_burnt)
            : 0;
          el.color = colorSelector(
            data.max_heart_rate_percent
              ? Math.round(data.max_heart_rate_percent)
              : 0
          );
          el.heartrate = data.heartrates.length
            ? data.heartrates[data.heartrates.length - 1]
                .heart_rate_at_the_instant
            : 0;
          el.maxHeartRate = Math.round(208 - 0.7 * 16);
        });
      if (allUsers.hasOwnProperty(data.user_id)) {
        allUsers[data.user_id].calories_burnt = data.calories_burnt
          ? Math.round(data.calories_burnt)
          : 0;
        allUsers[data.user_id].heartrate = data.current_heart_rate
          ? Math.round(data.current_heart_rate)
          : 0;
        allUsers[data.user_id].max_heart_rate_percent =
          data.max_heart_rate_percent
            ? Math.round(data.max_heart_rate_percent)
            : 0;
      }
      if (tagUsers.length) {
        const index = tagUsers.findIndex((el) => el.me);
        if (index > -1) {
          tagUsers[index].calories_burnt = data.calories_burnt
            ? Math.round(data.calories_burnt)
            : 0;
        }
      }
      usersInActivity.sort(caloriesSort);
      usersInActivity.forEach((el, index) => {
        if (
          allUsers.hasOwnProperty(el.user_id) &&
          (allUsers[el.user_id].is_best_attempt ? el.is_best_attempt : true)
        ) {
          allUsers[el.user_id].rank = index + 1;
        }
      });
      let temp = {
        maxHeartRatePercent: Math.round(data.max_of_max_heart_rate_percent),
        maxheartrate: Math.round(data.max_heart_rate),
        heartrate: Math.round(data.current_heart_rate),
        calories_burnt: Math.round(data.calories_burnt),
        max_heart_rate_percent: Math.round(data.max_heart_rate_percent),
      };

      this.setState({
        selfData: { ...data, ...temp },
        usersInActivity: usersInActivity,
        allUsers: allUsers,
      });
    }
  };

  tagged_videos = (data) => {
    const { allUsers, usersInActivity } = this.state;
    if (data.tagged_user_session_ids && data.tagged_user_session_ids.length) {
      data.tagged_user_session_ids.forEach((el) => {
        usersInActivity.forEach((element) => {
          if (
            el.user_activity_session_id === element.user_activity_session_id
          ) {
            this.start_stream(
              { ...allUsers[element.user_id], tagged_user_id: element.user_id },
              1
            );
            if (el === element.user_id && element.is_best_attempt === true) {
              element["stream_icon"] = true;
              element["showStream"] = true;
            }
          }
        });
      });
    }
    this.setState({
      usersInActivity: usersInActivity,
    });
  };

  toggle = () => {
    var video = document.getElementById("video-player");
    if (video !== undefined) {
      video.muted = !video.muted;
      this.setState({ mute: video.muted });
    }
  };

  webPreviewOff = (data) => {
    //////////consolele.log("webPreviewOff", data);
    const obj = {
      user_activity_session_id: data.user_activity_session_id,
      current_duration: this.state.totalSeconds,
      status: this.state.playerPlayStatus ? "PLAY" : "PAUSE",
    };
    setTimeout(() => {
      this.state.socket.emit("web_preview_off", obj, (el) => {
        // //////////consolele.log("webPreviewOff emit log", el);
        this.end_activity();
      });
    }, 300);
  };

  callListners = (status) => {
    ////////////consolele.log("Listner Called");
    this.state.socket[status]("activity_ended", this.end_activity);
    this.state.socket[status]("web_preview_off", this.webPreviewOff);
    this.state.socket[status]("video_tagged", (e) => this.start_stream(e, 1));
    this.state.socket[status]("video_untagged", (e) => this.end_stream(e, 1));
    this.state.socket[status]("self_heartbeat_sync", this.self_heartbeat);
    this.state.socket[status]("sync_tagged_videos", this.tagged_videos);
    this.state.socket[status]("ondemand_activity_sync", this.end_activity);
  };

  getData = () => {
    const { workoutData } = this.state;
    const formData = {
      gym_id: workoutData.gym_id,
      activity_id: workoutData.activity_id,
      duration_in_ms:
        new Date(workoutData.ended_at).getTime() -
        new Date(workoutData.started_at).getTime(),
    };
    axios.post(ApiURL._ondemandActivity, formData).then(
      async (response) => {
        // let userBestInfo = {};
        const data = response.data.data;

        this.setState({
          usersInActivity: [...data.usersInActivity],
          allUsers: { ...setUserInOndemandActivity(data) },
          new_user: { ...data.new_user },
          totalMembers: data.usersInActivity.length,
        });
        setTimeout(() => {
          this.callListners("on");
        }, 200);
        // setTimeout(() => {
        //     this.syncSocketEmitHandler({
        //         user_activity_session_id: ondemandData.user_activity_session_id,
        //         web_socket_id: socket.id
        //     });
        //     this.setState({
        //         firstTaggedEmit: true
        //     });
        // }, 1500);
      },
      (error) => {
        ////consolele.log("API ERROR");
      }
    );
  };

  start_stream = (data, status) => {
    this.tagUntagUser(data, status, true);
  };

  end_stream = (data, status) => {
    this.tagUntagUser(data, status, false);
  };

  tagUntagUser = (data, status, tagStatus) => {
    const userSessionId =
      data.tagged_user_activity_session_id || data.user_activity_session_id;
    // const userId = data.tagged_user_id || data.user_id;
    if (!CheckValue(userSessionId)) {
      return;
    }
    let userData = null;
    const { tagUsers, allUsers, new_user } = this.state;
    Object.values(allUsers)
      .filter((el) => el.user_activity_session_id === userSessionId)
      .forEach((el) => {
        el["showStream"] = tagStatus;
        if (!tagStatus && CheckValue(el.stream)) {
          el.stream.stop(userSessionId);
          delete el.stream;
        }
        userData = { ...el };
      });
    if (CheckValue(userData)) {
      const temp = tagUsers.findIndex((el) => el.user_id === userData.user_id);
      if (tagStatus) {
        if (temp < 0) {
          tagUsers.push({ ...userData });
        }
      } else {
        if (temp > -1) {
          tagUsers.splice(temp, 1);
        }
      }
    }
    this.setState({
      allUsers: allUsers,
      tagUsers: tagUsers,
    });

    if (!status) {
      const tagData = {
        user_id: new_user.user_id,
        user_activity_session_id: new_user.user_activity_session_id,
        activity_id: new_user.activity_id,
        tagged_user_id: data.user_id,
        tagged_user_activity_session_id: data.user_activity_session_id,
      };
      this.state.socket.emit(
        tagStatus ? "video_tagged" : "video_untagged",
        tagData,
        (obj) => {}
      );
    }
  };

  toggleBox = (type) => {
    this.setState({
      toggleDiv: {
        ...this.state.toggleDiv,
        [type]: !this.state.toggleDiv[type],
      },
    });
  };

  playPausePlayer = (status, id) => {
    const player = document.getElementById(id);
    if (CheckValue(player)) {
      if (status) {
        player.play();
      } else {
        player.pause();
      }
    }
  };

  videoControl = (type) => {
    if (type === "play") {
      // this.state.vid.play();
      playerPlaySubscriber.send(true);
      this.setState({
        playerPlayStatus: true,
      });
      localStorage.removeItem("videoPaused");
      this.playPausePlayer(true, "mediaAudio");
      this.playPausePlayer(true, "mediaVideo");
      const data = {
        user_activity_session_id:
          this.state.ondemandData.user_activity_session_id,
        status: "PLAY",
      };
      this.state.socket.emit("ondemand_activity_sync", data, (res) => {
        // console.log("ondemand_activity_sync-res",type, res);
      });
      this.startTimer();
    } else if (type === "pause") {
      // this.state.vid.pause();
      playerPlaySubscriber.send(false);
      this.setState({
        playerPlayStatus: false,
      });
      this.playPausePlayer(false, "mediaAudio");
      this.playPausePlayer(false, "mediaVideo");

      localStorage.setItem("videoPaused", "true2");
      const data = {
        user_activity_session_id:
          this.state.ondemandData.user_activity_session_id,
        status: "PAUSE",
      };
      this.state.socket.emit("ondemand_activity_sync", data, (res) => {
        // console.log("ondemand_activity_sync-res",type, res);
      });
      clearInterval(this.myInterval);
    } else if (
      type === "forward" &&
      this.state.totalSeconds + 10 <= this.state.MAX
    ) {
      this.updateSegmentArray(
        [...this.state.segmentDurations],
        this.state.totalSeconds + 10
      );
      this.setState({
        totalSeconds: this.state.totalSeconds + 10,
        values: [this.state.totalSeconds + 10],
      });
      seekVideoSubscriber.send(this.state.totalSeconds + 10);
    } else if (type === "backward") {
      if (this.state.totalSeconds - 10 < 0) {
        this.setState({
          totalSeconds: 0,
          values: [0],
        });
        seekVideoSubscriber.send(0);
      } else {
        this.updateSegmentArray(
          [...this.state.segmentDurations],
          this.state.totalSeconds - 10
        );
        this.setState({
          totalSeconds: this.state.totalSeconds - 10,
          values: [this.state.totalSeconds - 10],
        });
        seekVideoSubscriber.send(this.state.totalSeconds - 10);
      }
    }
  };

  mouseMoved = () => {
    this.setState({
      onDemandControls: this.state.MAX ? true : false,
    });

    clearTimeout(this.timer);
    this.timer = setTimeout(() => {
      this.setState({
        onDemandControls: false,
      });
    }, 4000);
  };

  get memberVideos() {
    return this.memberVideo.current;
  }

  get memberVideosData() {
    if (this.memberVideos) {
      return this.memberVideos.getMemberVideoData;
    } else {
      return {
        constraints: {
          audio: { autoplay: true, deviceId: "" },
          video: { width: 1280, height: 720, deviceId: "" },
        },
        permissions: false,
        isCameraEnabled: false,
        videoId: "memberVideo",
        webRTCAdaptor: null,
      };
    }
  }

  userPin = (id) => {
    const { allUsers } = this.state;
    if (allUsers.hasOwnProperty(id)) {
      if (allUsers[id].showStream) {
        this.end_stream(allUsers[id], 0);
      } else {
        const { allUsersArr } = getOndemandAvailableUser(allUsers, "all");
        if (allUsersArr.filter((el) => el.showStream).length < 5) {
          this.start_stream(allUsers[id], 0);
        } else {
          ErrorUI("You can pin up to 5 people.");
        }
      }
    }
  };

  getStaticData = (text, addNew = false) => {
    let temp = activityType1.filter((res) => res.value === text);
    if (!temp.length && addNew) {
      temp.push({ icon: null, name: text, value: text, child: [] });
    }
    return temp.length ? temp[0] : {};
  };

  // convertTime = (totalSeconds, type = ':') => {
  //     const second = totalSeconds % 60;
  //     const minute = parseInt((totalSeconds) / 60, 10);
  //     const minutes = parseInt((minute) % 60, 10);
  //     const hour = parseInt((minute) / 60, 10);

  //     let time = '';
  //     if (hour > 0) {
  //         time += this.checkLength(hour) + type;
  //     }
  //     time += this.checkLength(minutes) + type;
  //     time += this.checkLength(second);
  //     return time;
  // }

  render() {
    const {
      STEP,
      liveLatency,
      actionPerform,
      ongoing,
      required,
      gymData,
      totalSeconds,
      seg_total_width,
      is_WorkoutBuilder_Available,
      showSeekBar,
      onDemandControls,
      allUsers,
      id,
      value,
      socket,
      tagUsers,
      new_user,
      selfData,
      totalMembers,
      workoutData,
      segmentDurations,
      playerPlayStatus,
      MAX,
      MIN,
      values,
      seg_total_duration,
    } = this.state;

    //  New Code implement

    const { allUsersArr, allUserLength } = getOndemandAvailableUser(
      allUsers,
      "all"
    );
    const currentUserIndex = allUserLength
      ? allUsersArr.filter((el) => el.me)[0].rank
      : 0;

    const streamMember =
      Object.keys(new_user).length && actionPerform ? (
        <StreamMember
          pushSocket={false}
          socket={socket}
          ref={this.memberVideo}
          userActivityData={new_user}
        />
      ) : null;

    const hidden = {
      is_WorkoutBuilder_Available: is_WorkoutBuilder_Available,
      STEP,
      MIN,
      MAX,
      updateSegmentArray: this.updateSegmentArray,
      seg_total_width,
      seg_total_duration,
      values,
      segmentDurations,
      updateState: (el) => this.setState(el),
    };

    const onDemandControl = {
      controls: onDemandControls,
      playerPlayStatus: playerPlayStatus,
      videoControl: this.videoControl,
    };
    const canvas = (
      <canvas
        id="coach_canvas_view"
        className={`coachVideo`}
        width="1920"
        height="1080"
      ></canvas>
    );
    return (
      <section onMouseMove={this.mouseMoved} className="Casting background">
        <Header
          liveLatency={liveLatency}
          setData={(obj) => this.setState(obj)}
          waitingRoom={false}
          canvas={canvas}
          onDemandControl={{ ...onDemandControl }}
          hiddenControls={{ ...hidden }}
          stopCoachVideo={false}
          actionPerform={actionPerform}
          id={id}
          gymData={gymData}
          ongoing={ongoing}
          showSeekBar={showSeekBar}
          totalSeconds={totalSeconds < 0 ? 0 : totalSeconds}
          workoutData={workoutData}
          ondemand={true}
          low_latency_mode={false}
        />
        <BoardContainerOndemandRoom
          socket={socket}
          allUsers={allUsers}
          userActivityData={new_user}
          ondemand={true}
          userPin={this.userPin}
          required={required}
          totalMembers={totalMembers}
        />
        {/* <PinVideoView totalSeconds={totalSeconds} pinUserVideo={tagUsers} required={required} ondemand={true} /> */}
        {CheckAccess("userStream") ? (
          <PinVideoView
            totalSeconds={totalSeconds}
            pinUserVideo={tagUsers}
            required={required}
            ondemand={true}
            streamMember={streamMember}
            memberVideos={this.memberVideos}
          />
        ) : null}
        <FooterOngoing
          ondemand={true}
          id={id}
          value={value}
          required={required}
          selfData={selfData}
          rank={currentUserIndex}
          memberVideos={this.memberVideos}
        />
        <FullScreen />
      </section>
    );
  }
}

export default Ondemand;
