import './MemberStream.scss';
import { env } from '../../../services/CommonFunction';
import { WebRTCAdaptor } from '@antmedia/webrtc_adaptor';

const PlayStream = (props,startStream = () => null) => {
    const userId = props.user_activity_session_id;
    var sdpConstraints = {
        OfferToReceiveAudio: false,
        OfferToReceiveVideo: false
    };
    var mediaConstraints = {
        video: true,
        audio: false
    };

    let webRTCAdaptor = new WebRTCAdaptor({
        websocket_url: env('AntMediaUrl'),
        mediaConstraints: mediaConstraints,
        peerconnection_config: null,
        sdp_constraints: sdpConstraints,
        isPlayMode: true,
        debug: false,
        remoteVideoId: props.user_id+"_video",
        // bandwidth: int|string, // default is 900 kbps, string can be 'unlimited'
        dataChannelEnabled: false, // enable or disable data channel
        callback: function (info) {
            if (info === "initialized") {
                webRTCAdaptor.play(userId);
                //called by JavaScript SDK when WebSocket is connected. 
            } else if (info === "joinedTheRoom") {
                //called when this client is joined the room
                //obj contains streamId field which this client can use to publish to the room.
                //obj contains also the active streams in the room so that you can play them directly.
                //In order to get the streams in the room periodically call `getRoominfo`
            } else if (info === "play_started") {
                //play_started
                setTimeout(() => {
                    startStream();
                }, 1000);
            } else if (info === "newStreamAvailable") {
                //called when client is ready to play WebRTC stream.
            } else if (info === "publish_started") {
                //called when stream publishing is started for this client		
            } else if (info === "publish_finished") {
                //called when stream publishing has finished for this client
            } else if (info === "leavedFromRoom") {
                //called when this client is leaved from the room  	
            } else if (info === "closed") {
                //called when websocket connection is closed	
            } else if (info === "play_finished") {
                //called when a stream has finished playing	
            } else if (info === "streamInformation") {
                //called when a stream information is received from the server. 
                //This is the response of `getStreamInfo` method		
            } else if (info === "roomInformation") {
                //Called by response of `getRoomInfo` when a room information is received from the server.
                //It contains the array of the streams in the room.
            } else if (info === "data_channel_opened") {
                //called when data channel is opened
            } else if (info === "data_channel_closed") {
                // called when data channel is closed
            } else if (info === "data_received") {
                //called when data is received through data channel
            }
        },
        callbackError: function (error) {
            //some of the possible errors, NotFoundError, SecurityError,PermissionDeniedError
            console.info("error callback: " + error);
            // alert(error);
        }
    });
    return webRTCAdaptor;
}

export default PlayStream;