import { colorSelector } from "../../components/UI/selector";
import {
  checkMemberVideoPermission,
  checkRole,
  CheckValue,
  setStaff,
  StaffSort,
} from "../../services/CommonFunction";

export const socketConnected = () => {
  const ondemandData = { ...JSON.parse(localStorage.getItem("ondemandData")) };
  this.socketEmitHandler({
    activity_id: ondemandData.activity_id,
    user_activity_session_id: ondemandData.user_activity_session_id,
  });
};
export const updateHeartRate = (data, allUsers, setData) => {
  if (allUsers.hasOwnProperty(data.user_id)) {
    allUsers[data.user_id]["color"] = colorSelector(
      data.max_heart_rate_percent ? Math.round(data.max_heart_rate_percent) : 0
    );
    allUsers[data.user_id]["calories_burnt"] = data.calories_burnt
      ? Math.round(data.calories_burnt)
      : 0;
    allUsers[data.user_id]["heartrate"] = data.heartrate
      ? Math.round(data.heartrate)
      : 0;
    allUsers[data.user_id]["max_heart_rate_percent"] =
      data.max_heart_rate_percent ? Math.round(data.max_heart_rate_percent) : 0;
    if (setData) {
      setData(data);
    }
  }
};

export const updateRanking = (data, allUsers) => {
  data.forEach((el, index) => {
    if (allUsers.hasOwnProperty(el.user_id)) {
      allUsers[el.user_id].index = index;
    }
  });
};

export const staffJoined = (data, staffsInActivity) => {
  const { infoOfJoinedStaff } = data;
  let role =
    typeof infoOfJoinedStaff.roles === "string"
      ? [infoOfJoinedStaff.roles]
      : infoOfJoinedStaff.roles;
  if (checkRole("gym", role)) {
    return;
  }

  if (CheckValue(staffsInActivity[infoOfJoinedStaff.user_id])) {
    const { socket_id } = staffsInActivity[infoOfJoinedStaff.user_id];
    socket_id.push(data.socket_id);
    staffsInActivity[infoOfJoinedStaff.user_id].socket_id =
      StaffSort(socket_id);
  } else {
    staffsInActivity[infoOfJoinedStaff.user_id] = setStaff(
      { ...infoOfJoinedStaff, socket_id: data.socket_id },
      role
    );
  }
};

export const playMemberStream = (data, allUsers, userActivityData) => {
  if (
    allUsers.hasOwnProperty(data.user_id) &&
    (allUsers[data.user_id].me ||
      checkMemberVideoPermission(data.participant_video_visibility_in_attempt))
  ) {
    allUsers[data.user_id].stream_icon = true;
  }
};

export const setStartStream = (el) => {
  if (!el.isVideoStarted || !CheckValue(el.isVideoStarted)) {
    el.isVideoStarted = true;
  }
};
export const setStartStreamForRecording = (el, value = true) => {
  el.isVideoStarted = value;
};
export const stopMemberStream = async (data, allUsers, stateSet) => {
  if (allUsers.hasOwnProperty(data.user_id)) {
    if (CheckValue(allUsers[data.user_id].stream)) {
      await allUsers[data.user_id].stream.stop();
      delete allUsers[data.user_id].stream;
    }
    allUsers[data.user_id]["isVideoStarted"] = false;
    allUsers[data.user_id]["stream_icon"] = false;
    stateSet({ allUsers: allUsers });
  }
};

export const staffLeft = (data, staffsInActivity) => {
  const { infoOfLeftStaff } = data;
  if (
    CheckValue(infoOfLeftStaff && staffsInActivity[infoOfLeftStaff.user_id])
  ) {
    const { socket_id } = staffsInActivity[infoOfLeftStaff.user_id];
    const index = socket_id.findIndex((el) => el === infoOfLeftStaff.socket_id);
    if (index > -1) {
      socket_id.splice(index, 1);
    }
    if (!socket_id.length) {
      delete staffsInActivity[infoOfLeftStaff.user_id];
    }
  }
};
