import React, {
  Component,
  Fragment,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
// import './HLS.scss';
import Hls from "hls.js";
import {
  pattern,
  playerPlaySubscriber,
  seekVideoSubscriber,
} from "../../../services/subscriberService";
import PropTypes from "prop-types";
import { CheckValue } from "../../../services/CommonFunction";

// var caches;
class HLS extends Component {
  state = {
    vid: null,
    videoSrc: null,
    hls: null,
    version: "?v=1.0",
  };

  componentDidUpdate = async (nextProps) => {
    const src = this.checkSrc(nextProps.videoSrc);
    if (this.state.videoSrc !== src) {
      let temp = {
        videoSrc: src,
      };
      // this.state.vid.pause();
      if (CheckValue(this.state.hls)) {
        this.eventListner("off", this.state.hls, null);
        this.state.hls.destroy();
        temp["hls"] = null;
        // this.state.hls.loadSource(src);
      }
      this.setState({ ...temp });
      setTimeout(() => {
        this.videoHlr(src);
      }, 1000 * 0.3);
    }
  };

  checkSrc = (src) => {
    if (!CheckValue(src)) {
      return src;
    }
    return typeof src === "string" ? src : src.value;
  };

  componentDidMount = () => {
    this.setState({ vid: document.getElementById(this.props.id) });
    this.seekSubscription = seekVideoSubscriber.get().subscribe((data) => {
      if (CheckValue(this.props.currentData.video)) {
        const { play_at } = this.props.currentData.video;
        if (data.data > play_at && CheckValue(this.state.vid)) {
          const duration = this.state.vid.duration;
          let setTime = data.data - play_at;
          if (setTime > duration) {
            setTime = setTime / duration;
          }
          this.state.vid.currentTime = setTime;
        }
      }
    });
    this.playerPlaySubscription = playerPlaySubscriber
      .get()
      .subscribe((data) => {
        if(CheckValue(this.state.vid)) {
          if (data.data) {
            this.state.vid.play();
          } else {
            if (!this.state.isPausedOnce) {
              this.setState({ isPausedOnce: true });
            }
            this.state.vid.pause();
          }
        }
      });
  };

  componentWillUnmount() {
    this.seekSubscription.unsubscribe();
    this.playerPlaySubscription.unsubscribe();
    if (CheckValue(this.state.hls)) {
      this.eventListner("off", this.state.hls);
    }
    this.state.hls.destroy();
    if (this.props.isOndemand) {
      localStorage.setItem("videoNow", true);
    }
  }

  videoHlr = async (videoSrc, close = false) => {
    const obj = new RegExp(pattern.streamUrl);
    if (Hls.isSupported() && obj.test(videoSrc)) {
      if (CheckValue(this.state.vid)) {
        const hls = new Hls();
        hls.attachMedia(this.state.vid);
        this.setState({ hls: hls });
        this.eventListner("on", hls, videoSrc);
        // } else {
        //     this.videoHlr(videoSrc,close);
      }
    } else if (this.state.vid.canPlayType("application/vnd.apple.mpegurl")) {
      this.state.vid.addEventListener("loadedmetadata", function () {
        //  video.play();
      });
    }
  };

  eventListner = (status, hls, videoSrc = null) => {
    const { version } = this.state;
    const { id, addVersion } = this.props;
    const video = addVersion ? videoSrc : videoSrc + version;
    if (CheckValue(hls)) {
      hls[status](Hls.Events.MEDIA_ATTACHED, function () {
        hls.loadSource(video);
        // console.log('MEDIA_ATTACHED',id);
        hls[status](Hls.Events.MANIFEST_PARSED, function () {
          hls.startLoad(1);
          this.state.vid.play();
        });
      });

      hls[status](Hls.Events.LEVEL_LOADED, function (event, data) {});
      hls[status](Hls.Events.ERROR, function (event, data) {
        console.info("error", id, data);
      });
    }
  };

  render() {
    const { id, src, addVersion, controls, loop, canPlay, timeUpdate } =
      this.props;
    const { videoSrc, version } = this.state;
    let videoS = null;
    if (CheckValue(videoSrc)) {
      videoS = addVersion ? videoSrc + version : videoSrc;
    }
    return (
      <video
        id={id}
        preload="auto"
        src={videoS}
        loop={loop}
        muted
        autoPlay
        controls={controls}
        onPlay={(e) => {
          // if (localStorage.getItem('videoPausedOnce') === 'false' && localStorage.getItem('videoPaused') === "PAUSE") {
          //     e.target.pause();
          // }
        }}
        onCanPlay={canPlay}
        onTimeUpdate={timeUpdate}
        onPause={(e) => {}}
        onLoadedMetadata={(e) => {}}
        onLoadStart={(e) => {}}
        onSeeked={(e) => {}}
        poster={src}
      />
    );
  }
}

export default HLS;

HLS.propTypes = {
  id: PropTypes.string.isRequired,
  src: PropTypes.string,
  videoSrc: PropTypes.string,
  controls: PropTypes.bool,
  loop: PropTypes.bool,
  timeUpdate: PropTypes.func,
  canPlay: PropTypes.func,
};

HLS.defaultProps = {
  id: "videoId",
  src: null,
  videoSrc: null,
  controls: false,
  loop: false,
  timeUpdate: (e) => null,
  canPlay: (e) => null,
};

const HlsUI = (props) => {
  const { low_latency_mode, data, currentTime } = props;
  const [videoSrc, setVideoSrc] = useState(null);
  const [exercise_id, setExercise_id] = useState(null);
  const [isNewVideo, setIsNewVideo] = useState([]);
  const [isOldVideo, setIsOldVideo] = useState([]);
  const [is_video_play_in_loop, setIs_video_play_in_loop] = useState(false);
  const [is_video_muted, setIs_video_muted] = useState(true);

  const checkSrc = (video_url) => {
    if (!CheckValue(video_url)) {
      return video_url;
    }
    return typeof video_url === "string" ? video_url : video_url.value;
  };

  const canPlay = (e) => {
    e.target.volume = 0;
    e.target.muted = is_video_muted;
    setTimeout(() => {
      const setVolume = localStorage.getItem("setVolume");
      if (!e.target.muted) {
        if (!low_latency_mode) {
          if (CheckValue(setVolume) && setVolume / 100 !== e.target.volume) {
            e.target.volume = setVolume / 100;
          }
        } else {
          e.target.volume = 100 / 100;
        }
      }
    }, 200);
    const status = "videoNow";
    const videoNow = localStorage.getItem(status);
    const { play_at, duration } = data;
    // console.log(play_at,duration);
    let setTime = currentTime - play_at;
    // console.log('setTime',setTime);
    if (CheckValue(videoNow)) {
      if (CheckValue(duration) && duration && setTime > duration) {
        const loop = parseInt(setTime / duration, 10);
        setTime = setTime - duration * loop;
        setTime = parseInt(setTime, 10);
      }
      // console.log('setTime-1',setTime);
      if (Math.round(e.target.currentTime) !== setTime) {
        if (CheckValue(videoNow)) {
          localStorage.removeItem(status);
          setTime++;
        }
        // console.log('setTime-2',setTime);
        e.target.currentTime = setTime;
      }
    }
  };

  useEffect(() => {
    if (CheckValue(data.video_url)) {
      const video_url = checkSrc(data.video_url);
      setVideoSrc(checkSrc(data.video_url));
    }
  }, [data.video_url]);

  useEffect(() => {
    if (CheckValue(data.exercise_id) && data.exercise_id !== exercise_id) {
      setExercise_id(data.exercise_id);
      const video_url = checkSrc(data.video_url);
      if (video_url !== videoSrc) {
        setIsNewVideo([...isNewVideo, true]);
      } else {
        setIsOldVideo([...isOldVideo, true]);
      }
    }
  }, [data.exercise_id]);

  useEffect(() => {
    if (CheckValue(data.is_video_play_in_loop)) {
      setIs_video_play_in_loop(data.is_video_play_in_loop);
    }
  }, [data.is_video_play_in_loop]);

  useEffect(() => {
    if (CheckValue(data.is_video_muted)) {
      setIs_video_muted(data.is_video_muted);
    }
  }, [data.is_video_muted]);

  const hlsPlayer = useMemo(
    () => (
      <HlsPlayer
        canPlay={canPlay}
        videoSrc={videoSrc}
        is_video_play_in_loop={is_video_play_in_loop}
        isNewVideo={isNewVideo}
        isOldVideo={isOldVideo}
      />
    ),
    [videoSrc, is_video_play_in_loop, isNewVideo, isOldVideo]
  );

  return <Fragment>{hlsPlayer}</Fragment>;
};
const HlsPlayer = (props) => {
  const {
    is_video_play_in_loop,
    videoSrc,
    isNewVideo,
    isOldVideo,
    canPlay,
  } = props;
  const vidRef = useRef(null);
  const [isHls, setIsHls] = useState(true);
  const [isHlsError, setIsHlsError] = useState([]);

  const eventListner = (status) => {
    if (CheckValue(window.hls)) {
      window.hls[status](Hls.Events.MEDIA_ATTACHED, function () {
        window.hls.loadSource(videoSrc);
        // console.log('MEDIA_ATTACHED',id);
        window.hls[status](Hls.Events.MANIFEST_PARSED, function () {
          localStorage.setItem("videoNow", true);
          window.hls.startLoad(1);
          vidRef.current.play();
        });
      });

      window.hls[status](Hls.Events.LEVEL_LOADED, function (event, data) {});
      window.hls[status](Hls.Events.ERROR, function (event, data) {
        console.info("error", data);
        if (data.fatal) {
          switch (data.type) {
            case Hls.ErrorTypes.MEDIA_ERROR:
              console.info("fatal media error encountered, try to recover");
              window.hls.recoverMediaError();
              break;
            case Hls.ErrorTypes.NETWORK_ERROR:
              console.info("fatal network error encountered", data);
              // All retries and media options have been exhausted.
              // Immediately trying to restart loading could cause loop loading.
              // Consider modifying loading policies to best fit your asset and network
              // conditions (manifestLoadPolicy, playlistLoadPolicy, fragLoadPolicy).
              break;
            default:
              // cannot recover
              console.info("error", data);
              window.hls.destroy();
              break;
          }
        } else {
          setIsHlsError([...isHlsError, data]);
        }
      });
    }
  };

  const vidEventListner = (status) => {
    vidRef.current[status]("loadedmetadata", function () {
      vidRef.current.play();
    });
  };

  useEffect(() => {
    if (!isHls) {
      if (CheckValue(vidRef.current)) {
        vidEventListner("addEventListener");
      }
    }
    return () => {
      if (CheckValue(vidRef.current)) {
        vidEventListner("removeEventListener");
      }
    };
  }, [isHls]);

  useEffect(() => {
    const videoHlr = async () => {
      const obj = new RegExp(pattern.streamUrl);
      if (Hls.isSupported() && obj.test(videoSrc)) {
        if (CheckValue(vidRef.current)) {
          window.hls = new Hls();
          window.hls.attachMedia(vidRef.current);
          eventListner("on");
        }
      } else if (vidRef.current.canPlayType("application/vnd.apple.mpegurl")) {
        vidRef.current.src = videoSrc;
        setIsHls(false);
      }
    };
    if (isNewVideo.length || isHlsError.length) {
      videoHlr();
    }
    return () => {
      if (CheckValue(vidRef.current)) {
        vidRef.current.pause();
        setIsHls(true);
      }
      if (CheckValue(window.hls)) {
        eventListner("off");
        window.hls.destroy();
      }
    };
  }, [isNewVideo.length, isHlsError.length]);

  useEffect(() => {
    if (isOldVideo.length) {
      vidRef.current.currentTime = 0;
    }
  }, [isOldVideo.length]);


  return (
    <video
      id={"mediaVideo"}
      ref={vidRef}
      preload="auto"
      loop={is_video_play_in_loop}
      muted
      autoPlay
      controls={true}
      onPlay={(e) => {}}
      onCanPlay={canPlay}
      onTimeUpdate={(e) => {}}
      onPause={(e) => {
        localStorage.setItem("videoNow", true);
      }}
      onLoadedMetadata={(e) => {}}
      onLoadStart={(e) => {}}
      onSeeked={(e) => {}}
    />
  );
};

export { HlsUI };
