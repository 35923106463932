import { isBoolean } from "lodash";
import { colorSelector } from "../components/UI/selector";
import { access, mediaStream, staticVar } from "./Constants";

const AntMediaUrl = {
    local: 'wss://antmedia.tribe.fitness/WebRTCAppEE/websocket',
    develop: 'wss://antmedia.tribe.fitness/WebRTCAppEE/websocket',
    staging: 'wss://antmedia.tribe.fitness/staging/websocket',
    production: 'wss://antmedia.tribe.fitness/prod/websocket'
    // develop: 'ws://antmedia.tribe.fitness:5080/WebRTCAppEE/websocket',
    // staging: 'ws://antmedia.tribe.fitness:5080/staging/websocket',
    // production: 'ws://antmedia.tribe.fitness:5080/prod/websocket'
}

const Urls = {
    local: 'https://tribe.fitness.ngrok.io/',
    develop: 'https://dev.api.tribe.fitness/',
    staging: 'https://staging.api.tribe.fitness/',
    production: 'https://api.tribe.fitness/'
    // production: 'https://api.tribe.fitness/'
}

const OnDemandSocket = {
    // develop: 'http://dev-service-318849498.us-east-1.elb.amazonaws.com?access_token=',
    // staging: 'http://staging-service-550164730.us-east-1.elb.amazonaws.com?access_token=',
    // production: 'http://prod-service-1399505486.us-east-1.elb.amazonaws.com?access_token='
    local: 'https://tribe.fitness.ngrok.io?access_token=',
    develop: 'https://dev.api.tribe.fitness?access_token=',
    staging: 'https://staging.api.tribe.fitness?access_token=',
    production: 'https://api.tribe.fitness?access_token='
}
const SocketToken = {
    local: '',
    develop: '',
    staging: '',
    production: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzZXNzaW9uX2lkIjoiYzk1MzQ1MDUtODNjMi00MTIzLWJlNjQtY2RhMWQ2MjlkNTNkIiwiZGF0ZSI6IjIwMjEtMDUtMjJUMDU6MDk6MzUuOTYzWiIsImlhdCI6MTYyMTY2MDE3NX0.8sfzRwmOcFtnC-LPwAEtB4I7_paWALA3k6ALmRQFjAQ'
}

const checkAppId = {
    local: {
        TRIBWB1648388803072: 'theBeat',
        TRIBWB1657528203391: 'orangeTheory',
        TRIBWB1627993315526: 'upState',
        TRIBWB1659010349752: 'upBeat',
        TRIBWB1635760510031: 'YFN',
        TRIBWB1637146774910: 'woodsFit',
        TRIBWB1637146535779: 'swyftSweat',
        TRIBWB1638169823413: 'sweatShop',
        TRIBWB1640673172623: 'kerrySports',
        TRIBWB1646027997112: 'theUnion',
        TRIBWB1658908404535: 'plyGrd',
        TRIBWB1663005875954: 'barrys',
        TRIBWB1673327704546: 'therippleclub',
        TRIBWB1665993084817: 'f45digital',
        TRIBWB1666772626016: 'soulcycleDigital',
        TRIBWB1677054723935: 'gympasslive',
        TRIBWB1694512813603: 'theeLittleBird',
        TRIBWB1724860578300: 'engivio'

    },
    develop: {
        TRIBWB1648388803072: 'theBeat',
        TRIBWB1657528203391: 'orangeTheory',
        TRIBWB1627993315526: 'upState',
        TRIBWB1659010349752: 'upBeat',
        TRIBWB1635760510031: 'YFN',
        TRIBWB1637146774910: 'woodsFit',
        TRIBWB1637146535779: 'swyftSweat',
        TRIBWB1638169823413: 'sweatShop',
        TRIBWB1640673172623: 'kerrySports',
        TRIBWB1646027997112: 'theUnion',
        TRIBWB1658908404535: 'plyGrd',
        TRIBWB1663005875954: 'barrys',
        TRIBWB1665993084817: 'f45digital',
        TRIBWB1666772626016: 'soulcycleDigital',
        TRIBWB1673327704546: 'therippleclub',
        TRIBWB1691863815860: 'watchfit',
        TRIBWB1694512813603: 'theeLittleBird',
        // TRIBWB1690263775232: '1stPhorm',
        TRIBWB1724860578300: 'engivio'
    },
    staging: {
        TRIBWB1648619862223: 'theBeat',
        TRIBWB1657728584279: 'orangeTheory',
        // 'temp2': 'upState',
        // 'temp1': 'upBeat',
        TRIBWB1635760699335: 'YFN',
        TRIBWB1638159721525: 'woodsFit',
        TRIBWB1637147088865: 'swyftSweat',
        TRIBWB1638170077139: 'sweatShop',
        TRIBWB1648486423892: 'kerrySports',
        TRIBWB1655135130098: 'theUnion',
        TRIBWB1662143905647: 'plyGrd',
        TRIBWB1673510808618: 'therippleclub',
        TRIBWB1667375236505: 'f45digital',
        TRIBWB1668442537989: 'soulcycleDigital',
        TRIBWB1677054723935: 'gympasslive',
        TRIBWB1690263775232: '1stPhorm',
        TRIBWB1703176001318: 'theeLittleBird',
        TRIBWB1724860578300: 'engivio'
    },
    production: {
        TRIBWB1648619862223: 'theBeat',
        TRIBWB1657728584279: 'orangeTheory',
        // 'temp2': 'upState',
        // 'temp1': 'upBeat',
        TRIBWB1635760699335: 'YFN',
        TRIBWB1638159721525: 'woodsFit',
        TRIBWB1637147088865: 'swyftSweat',
        TRIBWB1638170077139: 'sweatShop',
        TRIBWB1648486423892: 'kerrySports',
        TRIBWB1655135130098: 'theUnion',
        TRIBWB1662143905647: 'plyGrd',
        TRIBWB1678872433465: 'therippleclub',
        TRIBWB1667375236505: 'f45digital',
        TRIBWB1668442537989: 'soulcycleDigital',
        TRIBWB1677054723935: 'gympasslive',
        TRIBWB1690263775232: '1stPhorm',
        TRIBWB1697140105807: 'theeLittleBird',
        TRIBWB1724860578300: 'engivio'
    },
}
const getStorage = (status) => JSON.parse(localStorage.getItem(status));
export const env = (status, types) => {

    // const type = 'local';
    // const type = 'develop';
    // const type = 'staging';
    const type = 'production';
    if (getStorage('local') && status === 'Urls') {
        return getStorage('local') + '/';
    } else if (getStorage('OnDemandSocket') && status === 'OnDemandSocket') {
        return getStorage('OnDemandSocket') + '?access_token=';
    } else if (getStorage('AntMediaUrl') && status === 'AntMediaUrl') {
        return getStorage('AntMediaUrl');
    } else if(getStorage('SocketToken') && status === 'SocketToken') {
        if(isBoolean(getStorage('SocketToken'))) {
            return eval(status)['production'];
        } else {
            return eval(status)['develop'];
        }
    } else {
        if(status === 'checkAppId' && getStorage('local')) {
            if(getStorage('local').includes("staging")) {
                return eval(status)['staging'];
            } else if(getStorage('local').includes("develop")) {
                return eval(status)['develop'];
            } else if(getStorage('local').includes("production")) {
                return eval(status)['production'];
            }
        }
        return eval(status)[types || type]
    }
}

// export const env = (status, types) => {
//     // const type = 'local';
//     // const type = 'develop';
//     const type = 'staging';
//     // const type = 'production';
//     return eval(status)[types || type]
// }

export const randomString = (length) => {
    var result = [];
    var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
        result.push(characters.charAt(Math.floor(Math.random() *
            charactersLength)));
    }
    return result.join('');
}

// New Code Implementation for New Casting Class
export const message = {
    list: {},
    count: 0
}

export const attendeeId = {}

// Show Message count
export const checkCount = (ref) => {
    return (ref.current && ref.current.count()) || 0;
}

export const convertTime = (totalSeconds, type = ':') => {
    const second = totalSeconds % 60;
    const minute = parseInt((totalSeconds) / 60, 10);
    const minutes = parseInt((minute) % 60, 10);
    const hour = parseInt((minute) / 60, 10);

    let time = '';
    if (hour > 0) {
        time += checkLength(hour) + type;
    }
    if (minutes > 0) {
        time += checkLength(minutes) + type;
    }
    time += checkLength(second);
    return time;
}
export const convertTime1 = (totalSeconds, type = ':', status = 'ms') => {
    const { hour, minutes, second } = getTime(totalSeconds);
    let time = '';
    let text = '';
    if (hour > 0) {
        text += 'h';
        time += checkLength(hour) + type;
    }
    if (minutes > 0) {
        text += 'm';
        time += checkLength(minutes) + type;
    } else if (status === 'ms') {
        text += 'm';
        time += '00' + type;
    }
    text += 's';
    time += checkLength(second);
    return {
        text,
        time
    };
}

export const getTime = (totalSeconds) => {
    const second = checkLength(totalSeconds % 60);
    const minute = parseInt((totalSeconds) / 60, 10);
    const minutes = checkLength(parseInt((minute) % 60, 10));
    const hour = checkLength(parseInt((minute) / 60, 10));
    return {
        hour,
        minutes,
        second
    };
}

export const timeUI = (totalSeconds, status = 'ms') => {
    const { hour, minutes, second } = getTime(totalSeconds);
    if (status === 'ms' && parseInt(hour, 10) > 0) {
        status = 'hms';
    }
    switch (status) {
        case 's':
            return second;
        case 'ms':
            return minutes + ':' + second;
        case 'hms':
        default:
            return hour + ':' + minutes + ':' + second;

    }
}

const checkLength = (data) => parseInt(data, 10) < 10 ? '0' + parseInt(data, 10) : parseInt(data, 10);

export const rankIndex = (a, b) => {
    var textA = a['index'];
    var textB = b['index'];
    return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
}

export const sortDate = (a, b) => {
    const dateA = typeof a.date === "number" ? a.date : Number(a.date);
    const dateB = typeof b.date === "number" ? b.date : Number(b.date);
    var textA = +new Date(dateA);
    var textB = +new Date(dateB);
    return (textA < textB) ? 1 : (textA > textB) ? -1 : 0;
}

export const checkRole = (status, roles) => {
    if (roles !== undefined) {
        if (status === 'gym') {
            return roles.includes('GYM_OWNER') || roles.includes('GYM_ADMIN');
        } else if (status === 'coach') {
            return roles.includes('COACH');
        } else if (status === 'all') {
            return roles.includes('COACH') && (roles.includes('GYM_OWNER') || roles.includes('GYM_ADMIN'));
        }
    }
    return false;
}

export const CheckValue = (value) => value !== null && value !== undefined && value !== "null" && value !== "undefined" && value !== "NaN" && value !== NaN;

export const StaffSort = (data) => data.filter((item, index, inputArray) => inputArray.indexOf(item) === index);

export const setStaff = (el, role) => ({
    first_name: el.first_name,
    last_name: el.last_name,
    profile_pic_original: el.profile_pic_original,
    profile_pic_thumb: el.profile_pic_thumb,
    // roles: checkRole('gym', role) ? 'owner' : 'coach',
    socket_id: [el.socket_id],
    user_id: el.user_id,
});

export const setCoach = (data) => ({
    first_name: data.first_name,
    last_name: data.last_name,
    created_by: data.created_by,
    user_id: data.created_by,
    profile_pic_thumb: data.profile_pic_thumb,
    waitingRoom_open_by: data.waiting_room_opened_by,
    host: data.waiting_room_opened_by === data.created_by,
    stream: false,
    owner_stream: false,
    isVideoStarted: false,
    owner_isVideoStarted: false,
    owner_web: false,
    owner_webTileId: null,
    owner_mobile: false,
    owner_mobileTileId: null,
    web: false,
    webTileId: null,
    mobile: false,
    mobileTileId: null,
});

export const setGym = (data) => ({
    name: data.gym_name,
    logo: data.logo_thumb,
    id: data.gym_id,
});

export const setWorkoutBuilder = (data, totalSeconds = 0) => {
    let prevMax = 0;
    data.workout_builder.sort(segmentSortOrder);
    data.workout_builder.forEach(el => {
        let prevMaxTemp = prevMax;
        prevMax += el.segment_duration_in_seconds
        el['combinedMax'] = prevMax;
        el['MIN'] = 0;
        el['MAX'] = el.segment_duration_in_seconds;
        el['STEP'] = 1;
        el['width'] = ((el.segment_duration_in_seconds / data.total_activity_duration_in_seconds) * 100) + '%';
        el.exercises.sort(exerciseSortOrder);
        el.exercises.forEach(ed => {
            prevMaxTemp += ed.exercise_duration_in_seconds;
            ed['combinedExerciseMax'] = prevMaxTemp;
        });
        el['values'] = [0];
    });
}

const exerciseSortOrder = (a, b) => {
    var textA = Number(a['exercise_order']);
    var textB = Number(b['exercise_order']);
    return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
}
export const segmentSortOrder = (a, b) => {
    var textA = Number(a['segment_order']);
    var textB = Number(b['segment_order']);
    return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
}

export const setStaffInActivity = (data, staffsInActivity) => {
    data.staffsInActivity.forEach(el => {
        let role = typeof el.roles === 'string' ? [el.roles] : el.roles;
        if (checkRole('gym', role)) {
            return;
        }
        if (CheckValue(staffsInActivity[el.user_id])) {
            staffsInActivity[el.user_id].socket_id.push(el.socket_id);
            staffsInActivity[el.user_id].socket_id = StaffSort(staffsInActivity[el.user_id].socket_id);
        } else {
            staffsInActivity[el.user_id] = setStaff(el, role);
        }
    });
}
export const participantVisibility = (data) => (!CheckValue(data.participant_video_visibility_in_attempt) || data.participant_video_visibility_in_attempt === 'ALL' || data.participant_video_visibility_in_attempt === 'FRIENDS_ONLY');

export const setUserInActivity = (data) => {
    const obj = {};
    if (data.usersInActivity && data.usersInActivity.length) {
        data.usersInActivity.forEach((element, index) => {
            obj[element.user_id] = setUserData({ ...element }, data.user_activity_data);
        });
    }
    return obj;
}

export const setUserData = (element, user_activity_data) => {
    element['calories_burnt'] = element['calories_burnt'] ? Math.round(element['calories_burnt']) : 0;
    element['showStream'] = false;
    element['maxHeartRate'] = Math.round(208 - (0.7 * element['age']));
    element['heartrate'] = Math.round(element['avg_heart_rate']);
    // element['heartrate'] = 0;
    element['muted'] = true;
    if (element.user_status_in_activity === 'ENDED') {
        element['color'] = '#808080';
        element['opacity'] = '0.6';
    } else if (element.user_status_in_activity === 'STARTED') {
        element['color'] = colorSelector(element['avg_heart_rate'] ? Math.round((element['avg_heart_rate'] / element['maxHeartRate']) * 100) : 0);
        element['opacity'] = 'unset';
    }
    element['me'] = element.user_id === user_activity_data.user_id;
    if (element.video_stream && (element['me'] || participantVisibility(element))) {
        element['stream_icon'] = true;
    } else {
        element['stream_icon'] = false;
    }

    return element;
}
export const setUserInOndemandActivity = (data) => {
    const obj = {};
    if (data.usersInActivity && data.usersInActivity.length) {
        data.usersInActivity.forEach((element, index) => {
            setOndemandUserData(element, index, data.new_user);
        });
        data.usersInActivity.sort(caloriesSort);
        data.usersInActivity.filter(el => el.is_best_attempt).forEach(el => {
            if (participantVisibility(el)) {
            } else {
                el.ant_media_recording = null;
                el.stream_icon = false;
            }
            if (el.me) {
                el.heartrate = 0;
                el.max_heart_rate_percent = 0;
            }
            if (!CheckValue(el.max_heart_rate_percent)) {
                el.max_heart_rate_percent = 0;
            }
            obj[el.user_id] = { ...el }
        });
        const meData = data.usersInActivity.filter(el => el.me);
        meData.filter(el => el.current_attempt).forEach(el => {
            if (participantVisibility(el)) {
                obj[el.user_id].ant_media_recording = el.ant_media_recording;
                obj[el.user_id].stream_icon = el.video_stream;
            } else {
                obj[el.user_id].ant_media_recording = null;
                obj[el.user_id].stream_icon = false;
            }
        });
        obj[data.new_user.user_id].total_attempts_yet = meData.length - 1;
        obj[data.new_user.user_id].user_activity_session_id = data.new_user.user_activity_session_id;
        obj[data.new_user.user_id].user_status_in_activity = staticVar.roomStarted;
    }
    return obj;
}

export const setOndemandUserData = (element, index, user_activity_data) => {
    element['calories_burnt'] = element['calories_burnt'] ? Math.round(element['calories_burnt']) : 0;
    element['maxHeartRate'] = Math.round(208 - (0.7 * element['age']));
    element['rank'] = index + 1;
    element['heartrate'] = Math.round(element['avg_heart_rate']);
    element['opacity'] = 'unset';
    element['showStream'] = false;
    element['isVideoStarted'] = false;
    element['color'] = colorSelector(element['avg_heart_rate'] ? Math.round((element['avg_heart_rate'] / element['maxHeartRate']) * 100) : 0);
    if (CheckValue(element['ant_media_recording']) && element['is_best_attempt'] && participantVisibility(element)) {
        element['stream_icon'] = true;
    } else {
        element['stream_icon'] = false;
    }
    if (element['is_best_attempt']) {
        if (element.user_id === user_activity_data.user_id) {
            element['is_best_attempt'] = false;
        }
        element['current_attempt'] = true;
    } else {
        element['current_attempt'] = false;
    }

    if (element.user_activity_session_id === user_activity_data.user_activity_session_id) {
        element['is_best_attempt'] = true;
        element['stream_icon'] = CheckValue(element['ant_media_recording']) && element['is_best_attempt'];
    }
    element['me'] = element.user_id === user_activity_data.user_id;
    return element;
}


export const activityPolling = (ondemandData, socket) => {
    const data = {
        user_activity_session_id: ondemandData.user_activity_session_id,
        status: 'PROGRESS'
    }
    socket.emit('ondemand_activity_sync', data, (res) => {});
}

export const currentWorkoutTime = (data, status) => {
    if (!CheckValue(data)) {
        return 0;
    }
    if (status) {
        return Math.round((new Date().getTime() - new Date(data).getTime()) / 1000);
    } else {
        return Math.round((new Date(data).getTime() - new Date().getTime()) / 1000);
    }

};

const deviceLabel = {
    audioin: "MacBook Pro Microphone (Built-in)",
    video: "FaceTime HD Camera (Built-in) (05ac:8514)",
    audioout: "MacBook Pro Speakers (Built-in)"
};
export const getDefaultAudioVideoDevice = (deviceList, deviceType = "audioout") => {
    if (!deviceList.length) {
        return null
    }
    const video = deviceList.find(el => el.label === deviceLabel[deviceType]);
    return video ? video.deviceId : deviceList[0].deviceId;
}


export const getMediaPermission = async (constraints, videoTagId, type = 0, status = true) => {
    try {
        // The MediaDevices.getUserMedia() method prompts the user for permission to use a media input
        //  which produces a MediaStream with tracks containing the requested types of media.
        await navigator.mediaDevices.getUserMedia(constraints).then(function (mediaStreams) {
            if (type) {
                mediaStream.stream = mediaStreams;
            } else if (CheckValue(videoTagId)) {
                const ele = document.getElementById(videoTagId);
                if (CheckValue(ele)) {
                    ele.srcObject = mediaStreams;
                }
            } else {
                mediaStreams.getAudioTracks().forEach(function (track) {
                    track.stop();
                });
                mediaStreams.getVideoTracks().forEach(function (track) {
                    track.stop();
                });
            }
        });
        return status ? getMediaDevices() : { status: true };
        // return { status: false };
    } catch (e) {
        return { status: false };
    }
}
export const stopMediaTrack = async (constraints) => {
    try {
        await navigator.mediaDevices.getUserMedia(constraints).then(function (mediaStream) {
            var tracks = mediaStream.getTracks();
            tracks.forEach(function (track) {
                track.stop();
            });
        });
        return true;
    } catch (e) {
        return false;
    }
}

export const getMediaDevices = async (type) => {
    try {
        const gotDevices = await navigator.mediaDevices.enumerateDevices()
        const videoInputDevices = gotDevices.filter(el => el.kind === 'videoinput' && el.deviceId !== 'default')
        const audioInputDevices = gotDevices.filter(el => el.kind === 'audioinput' && el.deviceId !== 'default')
        const audioOutputDevices = gotDevices.filter(el => el.kind === 'audiooutput' && el.deviceId !== 'default')
        return { status: true, videoInputDevices, audioInputDevices, audioOutputDevices, gotDevices }
    } catch (e) {
        return { status: false };
    }
    // Each object in the array describes one of the available media input and output devices
    //  (only device-types for which permission has been granted are "available"). 
    //  The order is significant - the default capture devices will be listed first.
}

export const getAvailableUser = (user, status) => {
    const allUsersArr = Object.values(user).filter(el => status === 'all' ? true : el.user_status_in_activity === status);
    allUsersArr.sort(rankIndex);
    // const allUsersArr = [...allUsersArr1,...allUsersArr1,...allUsersArr1,...allUsersArr1,...allUsersArr1];
    const allUserLength = allUsersArr.length;
    return {
        allUsersArr,
        allUserLength
    }
}

export const getOndemandAvailableUser = (user) => {
    const allUsersArr = Object.values(user).sort(rankSort);
    const allUserLength = allUsersArr.length;
    return {
        allUsersArr,
        allUserLength
    }
}

export const participantSort = (a, b) => {
    var textA = a['stream'];
    var textB = b['stream'];
    return (textA === textB) ? 0 : textA ? -1 : 1;
}

export const caloriesSort = (a, b) => {
    var textA = a['calories_burnt'];
    var textB = b['calories_burnt'];
    return (textA < textB) ? 1 : (textA > textB) ? -1 : 0;
}
export const rankSort = (a, b) => {
    var textA = a.rank;
    var textB = b.rank;
    return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
}

export const CheckAccess = (status) => {
    if (access[status]) {
        return access[status].value
    }
    return true;
}

export const checkMemberVideoPermission = (visibility) => !CheckValue(visibility) || visibility === 'ALL' || visibility === 'FRIENDS_ONLY';