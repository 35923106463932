import React, { Component } from 'react';
import './IVS.scss';
import { bufferingSubscriber, playerPlaySubscriber, seekCompleteSubscriber, seekVideoSubscriber } from '../../../services/subscriberService';
import { CheckValue } from '../../../services/CommonFunction';
import { changeVolume } from '../../../components/UI/InputRange/InputRange';
import { SVG, selectIcon } from '../../../components/UI/selector';
import { SmallLoader } from '../../../components/UI/Loader/Loader';


class IVS extends Component {

    state = {
        // playback_url: "https://d41b5ac60c77.us-east-1.playback.live-video.net/api/video/v1/us-east-1.771368300963.channel.uBrj3PuSRg0Y.m3u8"
        playback_url: null,
        ivsStatus: false,
        seekStatus: false,
        totalSeconds: 0,
        isOndemand: false,
        PlayerState: window.IVSPlayer.PlayerState,
        PlayerEventType: window.IVSPlayer.PlayerEventType,
        player: window.IVSPlayer.create(),
        loader: false
    }

    componentDidMount() {
        // console.log('enter');
        this.seekSubscription = seekVideoSubscriber.get().subscribe(data => {
            // console.log("seekSubscription", data);
            this.state.player.seekTo(data.data);
            this.changeAudioPlayerTime(data.data);
        });
        this.playerPlaySubscription = playerPlaySubscriber.get().subscribe(data => {
            if (data.data) {
                this.state.player.play();
            } else {
                this.state.player.pause();
            }
        });
    }

    changeAudioPlayerTime = (time) => {
        const audioPlayer = document.getElementById('mediaAudio');
        // console.log(this.props.currentData.audio)
        if(CheckValue(audioPlayer) && CheckValue(this.props.currentData.audio)) {
            // console.log('-----------------audioPlayer---------------');
            const { play_at } = this.props.currentData.audio;
            const duration = audioPlayer.duration;
            // console.log('duration',duration);
            let setTime = time - play_at;;
            if(setTime > duration) {
                setTime = setTime%duration;
            }
            // console.log('setTime', time, setTime);
            audioPlayer.currentTime = setTime;
        }

    }

    componentDidUpdate = (prevProps, prevState) => {
        if(this.props.ivsStatus !== this.state.ivsStatus || (CheckValue(this.props.URL) && this.props.URL !== this.state.playback_url)) {
            if (this.props.ivsStatus !== this.state.ivsStatus) {
                this.setState({ ivsStatus: this.props.ivsStatus });
            }
            if (CheckValue(this.props.URL) && this.props.URL !== this.state.playback_url) {
                this.setState({ playback_url: this.props.URL });
            }
            if (CheckValue(this.props.URL) && this.props.ivsStatus) {
                setTimeout(() => {                    
                    this.createPlayer(this.props.ivsStatus);
                }, 200);
            }
        }
    }

    componentWillUnmount() {
        this.eventListener('removeEventListener');
        this.seekSubscription.unsubscribe();
        this.playerPlaySubscription.unsubscribe();
        this.state.player.delete();
    }

    eventListener = (status) => {
        const { PlayerState, PlayerEventType, player } = this.state;
        player[status](PlayerState.PLAYING, () => {
            // console.log("Player State - PLAYING", player.isMuted());
            if(this.state.loader) {
                this.setState({ loader: false});
            }
            if (!this.state.seekStatus && this.props.isOndemand) {
                this.state.player.seekTo(this.props.totalSeconds);
                this.setState({
                    seekStatus: true
                });
            }
        });
        player[status](PlayerState.BUFFERING, data => {
            // console.log("player buffering", data);
            if(!this.state.loader) {
                this.setState({ loader: true});
            }
            bufferingSubscriber.send(true);    
        });
        player[status](PlayerState.ENDED, () => {
            // console.log("Player State - ENDED");
            if(this.props.isOndemand) {
                this.setState({
                    seekStatus: false
                });
            }
            this.setLiveLatency(0);
            if (this.state.ivsStatus) {
                player.setRebufferToLive(true);
                player.load(this.state.playback_url);
            }
        });
        player[status](PlayerEventType.TIME_UPDATE, () => {
            let latency = Math.round(player.getLiveLatency());
            if(latency < 2) {
                latency = 0;
            }
            this.setLiveLatency(latency);
        });
        player[status](PlayerState.IDLE, () => {
            // console.log("Player State - IDLE");
            this.setLiveLatency(0);
            if (!this.props.isOndemand && this.state.ivsStatus) {
                player.setRebufferToLive(true);
                player.load(this.state.playback_url);
            }
        });
        player[status](PlayerState.READY, () => {
            // console.log("Player State - READY");
            if (CheckValue(localStorage.getItem('setVolume'))) {
                player.setVolume(parseFloat(localStorage.getItem('setVolume')) / 100);
            }
            if(!this.props.ondemand) {
                player.play();
            }
        });
        player[status](PlayerEventType.ERROR, () => {
            // console.log("Player State - ERROR");
            if(!this.state.loader) {
                this.setState({ loader: true});
            }
            this.setState({
                seekStatus: false
            });
            this.setLiveLatency(0);
            if (!this.props.isOndemand && this.state.ivsStatus) {
                player.setRebufferToLive(true);
                player.load(this.state.playback_url);
            }
        });
        player[status](PlayerEventType.QUALITY_CHANGED, () => {
            // console.log("PlayerEvent - QUALITY_CHANGED: ", player.getQuality());
        });
        player[status](PlayerEventType.AUDIO_BLOCKED, () => {
            if (player.isMuted() && !CheckValue(localStorage.getItem('coachMuted'))) {
                changeVolume(0, this.props.id, 'undefined');
                // player.setMuted(false);
            }
            // console.log("PlayerEvent - AUDIO_BLOCKED: ", player.isMuted());
        });
        player[status](PlayerEventType.VOLUME_CHANGED, () => {
            // if(player.isMuted()) {
            //     player.setMuted(false);
            // }
            // console.log("PlayerEvent - VOLUME_CHANGED: ", player.getVolume(), player.isMuted());
        });
        player[status](PlayerEventType.MUTED_CHANGED, () => {
            // console.log("PlayerEvent - MUTED_CHANGED: ", player.isMuted());
        });
        player[status](PlayerEventType.WORKER_ERROR, () => {
            // console.log("PlayerEvent - WORKER_ERROR: ");
        });
        player[status](PlayerEventType.PLAYBACK_BLOCKED, () => {
            // console.log("PlayerEvent - PLAYBACK_BLOCKED: ");
            this.setLiveLatency(0);
        });
        player[status](PlayerEventType.SEEK_COMPLETED, data => {
            // console.log("playerSeekCompleted", data);
            seekCompleteSubscriber.send(Math.round(data));    
        });
    }

    setLiveLatency = (value) => {
        if( CheckValue(this.props.setData) && this.props.liveLatency !== value) {
            this.props.setData({liveLatency: value});
        }
    }

    createPlayer = (status) => {
        if (window.IVSPlayer.isPlayerSupported) {
            const { player } = this.state;
            player.attachHTMLVideoElement(document.getElementById(this.props.id));
            if (status) {
                player.setRebufferToLive(true);
                this.eventListener('addEventListener');
                // console.log('playback_url',this.state.playback_url);
                player.load(this.state.playback_url);
            } else {
                player.pause();
            }
        }
    }

    render() {
        const { loader } = this.state;
        return (
            <React.Fragment>
                <div className={`${CheckValue(this.props.ivsClass)?(this.props.ivsClass === 'full'?'IvsVideoBox':this.props.ivsClass):'IvsVideoBox'}`}>
                    <SmallLoader show={loader} />
                    {CheckValue(this.props.showSwap) && this.props.showSwap?<div className='swapIcon' onClick={this.props.swapClick}>
                        <SVG src={selectIcon('swapIcon')} />
                    </div>:null}

                    <video className="VideoTag"
                        id={this.props.id}
                        playsInline
                        autoPlay
                        height={300}
                        // onTimeUpdate={e => console.log(e.target.currentTime)}
                    // onPause={e => console.log(e.target.readyState)}
                    // onLoadedMetadata={e => console.log(e.target.readyState)}
                    // onLoadStart={e => console.log(e.target.readyState)}
                    // onLoad={e => console.log(e.target.readyState)}
                    // onLoadedData={e => console.log(e.target.readyState)}
                    // onCanPlay={e => console.log(e.target.readyState)}
                    // onCanPlayThrough={e => console.log(e.target.readyState)}

                    />
                </div>
            </React.Fragment>
        )
    }
}

export default IVS;