import React, { Component } from 'react';
import './Local.scss';
import { FormBuilder, FieldGroup, FieldControl } from "react-reactive-form";
import { CustomButton } from '../../components/UI/Button/Button';
import { FormGroups } from '../../components/ReusableComponents/FormGroups/FormGroups';
import { TextInput } from '../../components/UI/FormInput/FormInput';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClone } from '@fortawesome/free-solid-svg-icons';
import CopyToClipboard from 'react-copy-to-clipboard';
import { toasterSubscriber } from '../../services/subscriberService';

class LocalURL extends Component {

    state = {
        arr: ['local','OnDemandSocket','AntMediaUrl','SocketToken']
    }

    localForm = FormBuilder.group({
        local: [""],
        OnDemandSocket: [""],
        AntMediaUrl: [""],
        SocketToken: [false],
    });

    componentDidMount() {
        this.state.arr.forEach(el => {
            if (localStorage.getItem(el)) {
                this.localForm.patchValue({ [el] : JSON.parse(localStorage.getItem(el))})
            }
        });
    }

    clear = () => {
        this.state.arr.forEach(el => {
            localStorage.removeItem(el);
        });
        this.props.history.push('/home');
        // window.location.reload(true);
    }

    handleSubmit = (e) => {
        this.state.arr.forEach(el => {
            if(this.localForm.value[el] !== '') {
                if(el === 'SocketToken') {
                    localStorage.setItem('SocketToken',JSON.stringify(this.localForm.value[el]));
                } else {
                    localStorage.setItem(el,JSON.stringify(this.localForm.value[el]));
                }
            } else {
                localStorage.removeItem(el);
            }
        });
        this.props.history.push('/home');
        // window.location.reload(true);
    }

    render() {
        return(
            <div className="container local">
                <div className='staticText'>
                    <div>
                        <h2>Api/Socket Url</h2>
                        <ul>
                            <li>Local - https://local.api.tribe.fitness.ngrok.io <Copy text='https://local.api.tribe.fitness.ngrok.io' /></li>
                            <li>Dev - https://dev.api.tribe.fitness <Copy text='https://dev.api.tribe.fitness' /></li>
                            <li>Staging - https://staging.api.tribe.fitness <Copy text='https://staging.api.tribe.fitness' /></li>
                            <li>Prod - https://api.tribe.fitness <Copy text='https://api.tribe.fitness' /></li>
                        </ul>
                    </div>
                    <div>
                        <h2>AntMedia Url</h2>
                        <ul>
                            <li>Local - wss://antmedia.tribe.fitness/WebRTCAppEE/websocket <Copy text='wss://antmedia.tribe.fitness/WebRTCAppEE/websocket' /></li>
                            <li>Dev - wss://antmedia.tribe.fitness/WebRTCAppEE/websocket <Copy text='wss://antmedia.tribe.fitness/WebRTCAppEE/websocket' /></li>
                            <li>Staging - wss://antmedia.tribe.fitness/staging/websocket <Copy text='wss://antmedia.tribe.fitness/staging/websocket' /></li>
                            <li>Prod - wss://antmedia.tribe.fitness/prod/websocket <Copy text='wss://antmedia.tribe.fitness/prod/websocket' /></li>
                        </ul>
                    </div>
                </div>
                <FieldGroup control={this.localForm}
                    render={({ get, invalid }) => (
                        <form onSubmit={this.handleSubmit}>
                            <FormGroups>
                                <FieldControl name="local" render={TextInput} meta={{ label: "Enter Api URL", type: "text", placeholder: "", valid: false }} />
                            </FormGroups>
                            <FormGroups>
                                <FieldControl name="OnDemandSocket" render={TextInput} meta={{ label: "Enter Socket URL", type: "text", placeholder: "", valid: false }} />
                            </FormGroups>
                            <FormGroups>
                                <FieldControl name="AntMediaUrl" render={TextInput} meta={{ label: "Enter Ant Media URL", type: "text", placeholder: "", valid: false }} />
                            </FormGroups>
                            <FormGroups>
                                <FieldControl name="SocketToken" render={TextInput} meta={{ label: "Prod Socket", type: "checkbox", placeholder: "", valid: false }} />
                            </FormGroups>
                            <div className="login">
                                <CustomButton condition={true} label={`Submit`} />
                                <CustomButton condition={true} label={`Clear`} click={this.clear} />
                            </div>
                        </form>
                    )}
                />
            </div>
        )
    }
}

export default LocalURL;

const Copy = ({text,...props}) => (
    <CopyToClipboard text={text}>
        <span style={{ marginLeft: '5px', cursor: 'pointer' }} onClick={copyCode}><FontAwesomeIcon icon={faClone} /></span>
    </CopyToClipboard>
)

const copyCode = () => {
    toasterSubscriber.sendToasterData({
        showToaster: true,
        showImage: false,
        image: '',
        id: 'localCode',
        type: 'copy',
        message: 'Copied Successfully'
    });
}