import React from 'react';
import './Toaster.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';

// const checkTextLength = (text) => {
//     return text.length > 30 ? text.slice(0, 30) + '...' : text
// }
const Toaster = (props) =>

    props.data.showToaster ?

        (<div className="Toaster">
            {props.data.showImage ?
                <FontAwesomeIcon icon={faCheckCircle} />
                : null}
            <p> {props.data.message} </p>

        </div>) : null

export default Toaster;