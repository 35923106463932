import React from "react";
import "./Button.scss";

export const Button = ({
  label = "button",
  className = "btns-primary",
  handleClick = () => console.info("click"),
  style = {},
  invalid = false,
  disabled = false,
  type = "button",
}) => {
  // const { label, className, handleClick, style, invalid, disabled, type } =
  //   props;
  return (
    <React.Fragment>
      {type === "button" ? (
        <button
          type="button"
          style={style}
          className={`btns ${className} ${
            invalid || disabled ? "disabled" : ""
          }`}
          onClick={(e) => {
            e.preventDefault();
            handleClick();
          }}
        >
          {label}
        </button>
      ) : (
        <button
          type={type}
          style={style}
          className={`btns ${className} ${
            invalid || disabled ? "disabled" : ""
          }`}
          disabled={invalid || disabled}
        >
          {label}
        </button>
      )}
    </React.Fragment>
  );
};

export const CustomButton = (props) => {
  const { click, condition, label, clas } = props;
  return (
    <React.Fragment>
      {props.click ? (
        <button
          type="button"
          className={condition ? clas || "activeButton" : "disableButton"}
          onClick={(e) => {
            e.preventDefault();
            if (condition) {
              click(e);
            }
          }}
        >
          {label || "button"}
        </button>
      ) : (
        <button
          className={condition ? clas || "activeButton" : "disableButton"}
          disabled={!condition}
        >
          {label || "button"}
        </button>
      )}
    </React.Fragment>
  );
};
