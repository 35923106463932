import React, { Component } from "react";
import { Route } from "react-router-dom";
import NewCasting from "../../containers/NewCasting/NewCasting";
import Ondemand from "../../containers/Ondemand/Ondemand";
import { CheckValue } from "../../services/CommonFunction";
import "./InternalLayout.scss";
import { setColor, staticVar, themeData } from "../../services/Constants";
import { ClosePopup } from "../../components/UI/Modals/Modals";

class InternalLayout extends Component {
  componentDidMount() {
    localStorage.setItem("firstTime", "true");
    ["audioNow", "videoNow"].forEach((el) => localStorage.setItem(el, true));
    let appId = "tribe";
    if (CheckValue(localStorage.getItem("appId"))) {
      appId = localStorage.getItem("appId");
    }
    // appId = 'therippleclub';
    staticVar.appId = appId;
    setColor(themeData[appId]);
    const data = JSON.parse(localStorage.getItem("workoutData"));
    if (!CheckValue(data)) {
      this.props.history.push("/Home");
      return;
    }
    if (data.is_ondemand_workout) {
      this.props.history.push("/Ondemand");
    } else {
      if (data.activity_status === "STARTED") {
        this.props.history.push(`/LiveActivity`);
      } else if (data.activity_status === "IN_WAITING_ROOM") {
        this.props.history.push(`/LiveActivity`);
      }
    }
  }

  componentWillUnmount() {
    ClosePopup();
  }

  render() {
    return (
      <div>
        <Route path="/LiveActivity" component={NewCasting} />
        <Route path="/Ondemand" component={Ondemand} />
      </div>
    );
  }
}

export default InternalLayout;
