import axios from 'axios';
import {messageService} from './services/subscriberService';
import { env } from './services/CommonFunction';

const instance = axios.create({
    baseURL : env('Urls')
});

instance.interceptors.request.use(request => {
    if(messageService.showLoader) {
        messageService.sendMessage(true);
    }
    let token = localStorage.getItem('accessToken');
    if (token)  {
        request.headers.access_token = token;
    }   
    return request ;
},
error => {
    if(messageService.showLoader) {
        messageService.sendMessage(false);
    } else {
        messageService.showLoader = true;
    }

    return Promise.reject(error);
});

instance.interceptors.response.use(response => {
    if(messageService.showLoader) {
        messageService.sendMessage(false);
    } else {
        messageService.showLoader = true;
    }
    return response ;
},
error => {
    if(messageService.showLoader) {
        messageService.sendMessage(false);
    } else {
        messageService.showLoader = true;
    }
    return Promise.reject(error);
});

instance.defaults.headers.common['content-language'] = 'en';

export default instance;