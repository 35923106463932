import React, { Component } from "react";
import FullScreen from "../../../components/UI/FullScreen/FullScreen";
import { CheckValue, getAvailableUser } from "../../../services/CommonFunction";
import { Header } from "../Header/Header";
import StreamMember from "../StreamMember/StreamMember";
import {
  BoardContainerOngoingRoom,
  CurrentUser,
  FooterOngoing,
  PinVideoView,
  PinVideoViewZoom,
} from "../ChildComponentUI/ChildComponentUI";
import "./Ongoing.scss";
import { changeVolume } from "../../../components/UI/InputRange/InputRange";
import PlayStream from "../../Casting/MemberStream/MemberStream";
import { setStartStream } from "../SocketFun";

class Ongoing extends Component {
  constructor(props) {
    super(props);
    this.chatRef = React.createRef();
    this.streamRef = React.createRef();
    // this.zoomRef = React.createRef();
    this.state = {
      firstTime: false,
      value: 100,
      liveLatency: 0,
      id: "video-player",
      stopCoachVideo: CheckValue(localStorage.getItem("stopCoachVideo"))
        ? true
        : false,
    };
    // this.startTimer();
  }

  get video() {
    return document.getElementById(this.state.id);
  }

  componentDidMount() {
    localStorage.setItem("reload", true);
    if (CheckValue(localStorage.getItem("setVolume"))) {
      changeVolume(
        parseInt(localStorage.getItem("setVolume"), 10),
        this.state.id,
        "null"
      );
    } else {
      changeVolume(100, this.state.id, "null");
    }
    const {
      allUsers,
      tagUsers,
      zoom,
      low_latency_mode,
      mute_participants_by_default,
    } = this.props;
    const { allUsersArr } = getAvailableUser(allUsers, "all");
    const currentUser = allUsersArr.find((item) => item.me);
    if (low_latency_mode && CheckValue(currentUser)) {
      currentUser.showStream = true;
      if (CheckValue(zoom) && CheckValue(zoom.getLocalVideoStatus())) {
        currentUser["stream_icon"] = zoom.getLocalVideoStatus();
      }
      tagUsers.push(currentUser);
      // this.setStopCoachVideo(this.state.stopCoachVideo)
      if (CheckValue(zoom) && mute_participants_by_default) {
        zoom.muteByDeault();
      }
    }
  }

  setStopCoachVideo = (status) => {
    if (status) {
      localStorage.setItem("stopCoachVideo", true);
    } else {
      localStorage.setItem("audioNow", true);
      localStorage.setItem("videoNow", true);
      localStorage.removeItem("stopCoachVideo");
    }
    const { low_latency_mode, zoom } = this.props;
    if (low_latency_mode && CheckValue(zoom)) {
      zoom.startStopCoachVideo(!status);
    }
    this.setState({ stopCoachVideo: status });
  };

  componentWillUnmount() {}

  toggleBox = (type) => {
    this.setState((prevState) => ({
      toggleDiv: {
        ...prevState.toggleDiv,
        [type]: !prevState.toggleDiv[type],
      },
    }));
  };

  chatToggle = () => {
    this.setState((prevState) => ({
      chatUxToggle: !prevState.chatUxToggle,
    }));
  };

  stateSet = (obj) => {
    this.setState(obj);
  };

  get streamRefs() {
    return this.streamRef.current;
  }
  get streamRefData() {
    if (this.streamRefs) {
      return this.streamRefs.getStreamData;
    } else {
      return {
        constraints: { video: { width: 1280, height: 720, deviceId: "" } },
        isCameraEnabled: false,
        videoId: "memberVideo",
        webRTCAdaptor: null,
      };
    }
  }

  render() {
    const { value, id, stopCoachVideo, liveLatency } = this.state;
    const {
      canvas,
      webPreviewOff,
      actionPerform,
      totalSeconds,
      allUsers,
      tagUsers,
      required,
      userActivityData,
      workoutData,
      coachData,
      socket,
      gymData,
      selfData,
      ongoing,
      userPin,
      memberVideo,
      memberVideos,
      low_latency_mode,
      zoom,
      zoomData,
      mute_participants_by_default,
    } = this.props;
    const { allUsersArr } = getAvailableUser(allUsers, "all");
    const currentUserIndex = allUsersArr.findIndex((el) => el.me);

    let pinUserVideo = [];
    let streamMember;
    if (low_latency_mode) {
      allUsersArr.forEach((obj1) => {
        tagUsers.forEach((obj2) => {
          if (obj2.user_id === obj1.user_id) {
            pinUserVideo.push(obj1);
          }
        });
      });
    } else {
      pinUserVideo = tagUsers
        .filter((el) => el.showStream)
        .map((data) => {
          const el = allUsers[data.user_id];
        if (el.stream_icon) {
          if (
            !CheckValue(el.stream) &&
            document.getElementById(el.user_id)
          ) {
            el.stream = PlayStream({ ...el }, () => setStartStream(el));
          }
        } else {
            if (CheckValue(el.stream)) {
                if (typeof el.stream !== "boolean") {
                el.stream.stop(el.user_activity_session_id);
                delete el.stream;
                }
            }
        }
          return el;
        });

      streamMember =
        Object.keys(userActivityData).length ? (
          <StreamMember
            pushSocket={true}
            socket={socket}
            ref={memberVideo}
            userActivityData={userActivityData}
          />
        ) : null;
    }

    let coachVideoStatus = false;
    if (CheckValue(zoom)) {
      coachVideoStatus = zoom.getCoachVideoStatus();
    }
    return (
      <React.Fragment>
        <section className="Casting background">
          {low_latency_mode ? (
            <PinVideoViewZoom
              zoomData={zoomData}
              {...this.props}
              pinUserVideos={pinUserVideo}
              zoom={zoom}
            />
          ) : (
            <PinVideoView
              pinUserVideo={pinUserVideo}
              required={required}
              streamMember={streamMember}
              memberVideos={memberVideos}
              low_latency_mode={low_latency_mode}
              zoom={low_latency_mode ? zoom : null}
            />
          )}
          <Header
            liveLatency={liveLatency}
            setData={(obj) => this.setState(obj)}
            coachData={coachData}
            canvas={canvas}
            coachVideoStatus={coachVideoStatus}
            stopCoachVideo={stopCoachVideo}
            actionPerform={actionPerform}
            ondemand={false}
            id={id}
            gymData={gymData}
            ongoing={ongoing}
            showSeekBar={true}
            totalSeconds={totalSeconds < 0 ? 0 : totalSeconds}
            workoutData={workoutData}
            low_latency_mode={low_latency_mode}
          />
          <BoardContainerOngoingRoom
            {...this.props}
            userPin={userPin}
            chatRef={this.chatRef}
            required={required}
          />
          
          <FooterOngoing
            webPreviewOff={webPreviewOff}
            mute_participants_by_default={mute_participants_by_default}
            ondemand={false}
            stopCoachVideo={stopCoachVideo}
            setStopCoachVideo={this.setStopCoachVideo}
            id={id}
            value={value}
            required={required}
            selfData={selfData}
            rank={currentUserIndex + 1}
            memberVideos={memberVideos}
            low_latency_mode={low_latency_mode}
            zoom={low_latency_mode ? zoom : null}
          />
          <FullScreen />
        </section>
      </React.Fragment>
    );
  }
}

export default Ongoing;