import React, { Component } from 'react';
import Toaster from '../../components/UI/Toaster/Toaster';
import { toasterSubscriber } from '../../services/subscriberService';

class Layout extends Component {
    state = {
        toasterData: []
    }
    componentDidMount() {
        this.subscription = toasterSubscriber.getToasterData().subscribe(data => {
            if (data.data.type && data.data.type === 'copy') {
                const temp = this.state.toasterData.filter(el => el.type && el.type === 'copy' && el.id && el.id === data.data.id);
                if (temp.length) {
                    return;
                }
            }
            this.setState(prevState => ({
                toasterData: [...prevState.toasterData, data.data]
            }));
        });
    }

    componentDidUpdate = async (nextProps, nextState) => {
        if (this.state.toasterData.length && this.state.toasterData !== nextState.toasterData) {
            setTimeout(() => {
                this.setState(prevState => ({
                    toasterData: prevState.toasterData.splice(1, prevState.toasterData.length - 1)
                }));
            }, 3000);
        }
    }

    render() {
        const toast = this.state.toasterData.map((el, index) => <Toaster key={index} data={el} />);
        return(
            <React.Fragment>
                <div className="toaster_div">
                    {toast}
                </div>
                <div>
                    { this.props.children }
                </div>
            </React.Fragment>
        )
    }
    
}

export default Layout;