import { selectIcon } from "../components/UI/selector";
import { CheckValue } from "./CommonFunction";

export const staticVar = {
  version: "6.5.5",
  scheduled: "SCHEDULED",
  waitingRoom: "IN_WAITING_ROOM",
  roomStarted: "STARTED",
  waiting: "WAITING",
  ended: "ENDED",
  textMsg: "We are using your web's camera and mic",
  appId: "tribe",
};
export const options = {
  hour: "numeric",
  minute: "numeric",
  second: "numeric",
  hour12: true,
};

export const defaultValue = {
  limit: 4,
  height: "calc((100% - 30px) / 3)",
  width: "calc((100% - 75px) / 4)",
};

export const mediaStream = {
  stream: null,
};

export const access = {
  userStream: { show: false, label: "UserStream in ondemand", value: false },
};

export const activityType1 = [
  {
    icon: selectIcon("dance"),
    name: "Dance",
    value: "Dance",
    child: ["Pole", "Aerial", "Zumba", "Aero Dance"],
  },
  {
    icon: selectIcon("cardio"),
    name: "Cardio",
    value: "Cardio",
    child: [
      "Running",
      "Walking",
      "Rowing",
      "Tread",
      "Jump Rope",
      "Swimming",
      "TABATA",
    ],
  },
  {
    icon: selectIcon("mobility"),
    name: "Mobility",
    value: "Mobility",
    child: [],
  },
  {
    icon: selectIcon("streching"),
    name: "Stretching",
    value: "Stretching",
    child: [],
  },
  {
    icon: selectIcon("meditation"),
    name: "Meditation",
    value: "Meditation",
    child: [],
  },
  {
    icon: selectIcon("yoga"),
    name: "Yoga",
    value: "Yoga",
    child: [
      "Vinyasa",
      "Restorative",
      "Prenatal",
      "Postnatal",
      "Slow Flow",
      "Ashtanga",
      "Power Yoga",
    ],
  },
  { icon: selectIcon("pilates"), name: "Pilates", value: "Pilates", child: [] },
  { icon: selectIcon("barre"), name: "Barre", value: "Barre", child: [] },
  {
    icon: selectIcon("bootcamp"),
    name: "Bootcamp",
    value: "Bootcamp",
    child: [],
  },
  {
    icon: selectIcon("boxing"),
    name: "Boxing",
    value: "Boxing",
    child: ["Kickboxing", "MMA", "Total-Body", "Shadow Boxing"],
  },
  { icon: selectIcon("sports"), name: "Sports", value: "Sports", child: [] },
  {
    icon: selectIcon("celebrity"),
    name: "Celebrity",
    value: "Celebrity",
    child: [],
  },
  { icon: selectIcon("core"), name: "Core", value: "Core", child: [] },
  { icon: selectIcon("cycling"), name: "Cycling", value: "Cycling", child: [] },
  { icon: selectIcon("hiit"), name: "HIIT", value: "HIIT", child: [] },
  {
    icon: selectIcon("seniorFitness"),
    name: "Senior Fitness",
    value: "Senior Fitness",
    child: [],
  },
  {
    icon: selectIcon("strengths"),
    name: "Strength",
    value: "Strength",
    child: [],
  },
  {
    icon: selectIcon("calisthenics"),
    name: "Calisthenics",
    value: "Calisthenics",
    child: [],
  },
  {
    icon: selectIcon("functional"),
    name: "Functional",
    value: "Functional",
    child: [],
  },
  { icon: selectIcon("toning"), name: "Toning", value: "Toning", child: [] },
  { icon: selectIcon("other"), name: "Others", value: "Others", child: [] },
];

export const themeData = {
  gympasslive: {
    theme: "light",
    chatCountBackgroundColor: "#D8385E",
    primaryColor: "#D8385E",
    secondaryColor: "#FFFFFF",
    chatTextColor: "#424242",
    tertioryColor: "#FF4438",
    primaryTextColor: "#FFFFFF",
    secondaryTextColor: "#505050",
    chatBackgroudColor: "#505050",
    boxBorderColor: "#A6A6A6",
    layerOne: "#E5E5E5",
    backgroundColor: "rgba(229, 229, 229, 0.6)",
    background: "#FFFFFF",
    senderBackground: "#FFFFFF", //chat sender background color
    receiverBackground: "#505050", //chat receiver background color
    personalBackground: "#D8385E", //chat sender/receiver background color
    senderBackgroundText: "#000000", //chat sender text background color
    receiverBackgroundText: "#FFFFFF", //chat receiver text background color
    personalBackgroundText: "#FFFFFF", //chat sender/receiver text background color
  },
  orangeTheory: {
    theme: "light",
    primaryColor: "#ED700F",
    chatCountBackgroundColor: "#ED700F",
    secondaryColor: "#FFFFFF",
    chatTextColor: "#424242",
    tertioryColor: "#075D7E",
    primaryTextColor: "#FFFFFF",
    secondaryTextColor: "#505050",
    chatBackgroudColor: "#505050",
    boxBorderColor: "#A6A6A6",
    layerOne: "#E5E5E5",
    backgroundColor: "rgba(229, 229, 229, 0.6)",
    background: "#FFFFFF",
    senderBackground: "#FFFFFF", //chat sender background color
    receiverBackground: "#505050", //chat receiver background color
    personalBackground: "#ED700F", //chat sender/receiver background color
    senderBackgroundText: "#000000", //chat sender text background color
    receiverBackgroundText: "#FFFFFF", //chat receiver text background color
    personalBackgroundText: "#FFFFFF", //chat sender/receiver text background color
  },
  theBeat: {
    theme: "light",
    primaryColor: "#595BAF",
    chatCountBackgroundColor: "#595BAF",
    secondaryColor: "#FFFFFF",
    chatTextColor: "#424242",
    tertioryColor: "#63BCC9",
    primaryTextColor: "#FFFFFF",
    secondaryTextColor: "#505050",
    chatBackgroudColor: "#505050",
    boxBorderColor: "#A6A6A6",
    layerOne: "#E5E5E5",
    backgroundColor: "rgba(229, 229, 229, 0.6)",
    background: "#FFFFFF",
    senderBackground: "#FFFFFF", //chat sender background color
    receiverBackground: "#505050", //chat receiver background color
    personalBackground: "#595BAF", //chat sender/receiver background color
    senderBackgroundText: "#000000", //chat sender text background color
    receiverBackgroundText: "#FFFFFF", //chat receiver text background color
    personalBackgroundText: "#FFFFFF", //chat sender/receiver text background color
  },
  theUnion: {
    theme: "dark",
    primaryColor: "#DFFF00",
    chatCountBackgroundColor: "#DFFF00",
    secondaryColor: "#424242",
    chatTextColor: "#424242",
    tertioryColor: "#3EC70B",
    primaryTextColor: "#222222",
    secondaryTextColor: "#FFFFFF",
    chatBackgroudColor: "#FFFFFF",
    boxBorderColor: "#767676",
    layerOne: "#222222",
    backgroundColor: "rgba(0, 0, 0, 0.6)",
    background: "#222222",
    senderBackground: "#FFFFFF", //chat sender background color
    receiverBackground: "#505050", //chat receiver background color
    personalBackground: "#DFFF00", //chat sender/receiver background color
    senderBackgroundText: "#000000", //chat sender text background color
    receiverBackgroundText: "#FFFFFF", //chat receiver text background color
    personalBackgroundText: "#FFFFFF", //chat sender/receiver text background color
  },
  upBeat: {
    theme: "light",
    primaryColor: "#ED2126",
    chatCountBackgroundColor: "#ED2126",
    secondaryColor: "#FAF7F5",
    chatTextColor: "#424242",
    tertioryColor: "#F8ACB6",
    primaryTextColor: "#FFFFFF",
    secondaryTextColor: "#505050",
    chatBackgroudColor: "#505050",
    boxBorderColor: "#A6A6A6",
    layerOne: "#E5E5E5",
    backgroundColor: "rgba(229, 229, 229, 0.6)",
    background: "#FFFFFF",
    senderBackground: "#FFFFFF", //chat sender background color
    receiverBackground: "#505050", //chat receiver background color
    personalBackground: "#ED2126", //chat sender/receiver background color
    senderBackgroundText: "#000000", //chat sender text background color
    receiverBackgroundText: "#FFFFFF", //chat receiver text background color
    personalBackgroundText: "#FFFFFF", //chat sender/receiver text background color
  },
  kerrySports: {
    theme: "dark",
    primaryColor: "#E7E347",
    chatCountBackgroundColor: "#E7E347",
    secondaryColor: "#424242",
    chatTextColor: "#424242",
    tertioryColor: "#3AB4F2",
    primaryTextColor: "#222222",
    secondaryTextColor: "#FFFFFF",
    chatBackgroudColor: "#FFFFFF",
    boxBorderColor: "#767676",
    layerOne: "#222222",
    backgroundColor: "rgba(0, 0, 0, 0.6)",
    background: "#222222",
    senderBackground: "#FFFFFF", //chat sender background color
    receiverBackground: "#505050", //chat receiver background color
    personalBackground: "#E7E347", //chat sender/receiver background color
    senderBackgroundText: "#000000", //chat sender text background color
    receiverBackgroundText: "#FFFFFF", //chat receiver text background color
    personalBackgroundText: "#FFFFFF", //chat sender/receiver text background color
  },
  upState: {
    theme: "dark",
    primaryColor: "#F7DD5E",
    chatCountBackgroundColor: "#F7DD5E",
    secondaryColor: "#424242",
    chatTextColor: "#424242",
    tertioryColor: "#D61C4E",
    primaryTextColor: "#222222",
    secondaryTextColor: "#FFFFFF",
    chatBackgroudColor: "#FFFFFF",
    boxBorderColor: "#767676",
    layerOne: "#222222",
    backgroundColor: "rgba(0, 0, 0, 0.6)",
    background: "#222222",
    senderBackground: "#FFFFFF", //chat sender background color
    receiverBackground: "#505050", //chat receiver background color
    personalBackground: "#F7DD5E", //chat sender/receiver background color
    senderBackgroundText: "#000000", //chat sender text background color
    receiverBackgroundText: "#FFFFFF", //chat receiver text background color
    personalBackgroundText: "#FFFFFF", //chat sender/receiver text background color
  },
  YFN: {
    theme: "dark",
    primaryColor: "#E35F21",
    chatCountBackgroundColor: "#E35F21",
    secondaryColor: "#424242",
    chatTextColor: "#424242",
    tertioryColor: "#017A95",
    primaryTextColor: "#FFFFFF",
    secondaryTextColor: "#FFFFFF",
    chatBackgroudColor: "#FFFFFF",
    boxBorderColor: "#767676",
    layerOne: "#222222",
    backgroundColor: "rgba(0, 0, 0, 0.6)",
    background: "#222222",
    senderBackground: "#FFFFFF", //chat sender background color
    receiverBackground: "#505050", //chat receiver background color
    personalBackground: "#E35F21", //chat sender/receiver background color
    senderBackgroundText: "#000000", //chat sender text background color
    receiverBackgroundText: "#FFFFFF", //chat receiver text background color
    personalBackgroundText: "#FFFFFF", //chat sender/receiver text background color
  },
  swyftSweat: {
    theme: "dark",
    primaryColor: "#4F14FF",
    chatCountBackgroundColor: "#4F14FF",
    secondaryColor: "#767676",
    chatTextColor: "#424242",
    tertioryColor: "#AABDFF",
    primaryTextColor: "#222222",
    secondaryTextColor: "#FFFFFF",
    chatBackgroudColor: "#FFFFFF",
    boxBorderColor: "#767676",
    layerOne: "#222222",
    backgroundColor: "rgba(0, 0, 0, 0.6)",
    background: "#222222",
    senderBackground: "#FFFFFF", //chat sender background color
    receiverBackground: "#505050", //chat receiver background color
    personalBackground: "#4F14FF", //chat sender/receiver background color
    senderBackgroundText: "#000000", //chat sender text background color
    receiverBackgroundText: "#FFFFFF", //chat receiver text background color
    personalBackgroundText: "#FFFFFF", //chat sender/receiver text background color
  },
  woodsFit: {
    theme: "dark",
    primaryColor: "#FF6700",
    chatCountBackgroundColor: "#FF6700",
    secondaryColor: "#424242",
    chatTextColor: "#424242",
    tertioryColor: "#F79500",
    primaryTextColor: "#FFFFFF",
    secondaryTextColor: "#FFFFFF",
    chatBackgroudColor: "#FFFFFF",
    boxBorderColor: "#767676",
    layerOne: "#222222",
    backgroundColor: "rgba(0, 0, 0, 0.6)",
    background: "#222222",
    senderBackground: "#FFFFFF", //chat sender background color
    receiverBackground: "#505050", //chat receiver background color
    personalBackground: "#FF6700", //chat sender/receiver background color
    senderBackgroundText: "#000000", //chat sender text background color
    receiverBackgroundText: "#FFFFFF", //chat receiver text background color
    personalBackgroundText: "#FFFFFF", //chat sender/receiver text background color
  },
  sweatShop: {
    theme: "dark",
    primaryColor: "#A5C439",
    chatCountBackgroundColor: "#A5C439",
    secondaryColor: "#424242",
    chatTextColor: "#424242",
    tertioryColor: "#39174B",
    primaryTextColor: "#FFFFFF",
    secondaryTextColor: "#FFFFFF",
    chatBackgroudColor: "#FFFFFF",
    boxBorderColor: "#767676",
    layerOne: "#222222",
    backgroundColor: "rgba(0, 0, 0, 0.6)",
    background: "#222222",
    senderBackground: "#FFFFFF", //chat sender background color
    receiverBackground: "#505050", //chat receiver background color
    personalBackground: "#A5C439", //chat sender/receiver background color
    senderBackgroundText: "#000000", //chat sender text background color
    receiverBackgroundText: "#FFFFFF", //chat receiver text background color
    personalBackgroundText: "#FFFFFF", //chat sender/receiver text background color
  },
  plyGrd: {
    theme: "dark",
    primaryColor: "#51AE6C",
    chatCountBackgroundColor: "#51AE6C",
    secondaryColor: "#424242",
    chatTextColor: "#424242",
    tertioryColor: "#844B28",
    primaryTextColor: "#FFFFFF",
    secondaryTextColor: "#FFFFFF",
    chatBackgroudColor: "#FFFFFF",
    boxBorderColor: "#767676",
    layerOne: "#222222",
    backgroundColor: "rgba(0, 0, 0, 0.6)",
    background: "#222222",
    senderBackground: "#FFFFFF", //chat sender background color
    receiverBackground: "#505050", //chat receiver background color
    personalBackground: "#51AE6C", //chat sender/receiver background color
    senderBackgroundText: "#000000", //chat sender text background color
    receiverBackgroundText: "#FFFFFF", //chat receiver text background color
    personalBackgroundText: "#FFFFFF", //chat sender/receiver text background color
  },
  barrys: {
    theme: "dark",
    primaryColor: "#D61638",
    chatCountBackgroundColor: "#D61638",
    secondaryColor: "#424242",
    chatTextColor: "#424242",
    tertioryColor: "#D61638",
    primaryTextColor: "#FFFFFF",
    secondaryTextColor: "#FFFFFF",
    chatBackgroudColor: "#FFFFFF",
    boxBorderColor: "#767676",
    layerOne: "#222222",
    backgroundColor: "rgba(0, 0, 0, 0.6)",
    background: "#222222",
    senderBackground: "#FFFFFF", //chat sender background color
    receiverBackground: "#505050", //chat receiver background color
    personalBackground: "#D61638", //chat sender/receiver background color
    senderBackgroundText: "#000000", //chat sender text background color
    receiverBackgroundText: "#FFFFFF", //chat receiver text background color
    personalBackgroundText: "#FFFFFF", //chat sender/receiver text background color
  },
  f45digital: {
    theme: "light",
    primaryColor: "#211551",
    chatCountBackgroundColor: "#211551",
    secondaryColor: "#FFFFFF",
    chatTextColor: "#424242",
    tertioryColor: "#D6001C",
    primaryTextColor: "#FFFFFF",
    secondaryTextColor: "#505050",
    chatBackgroudColor: "#505050",
    boxBorderColor: "#A6A6A6",
    layerOne: "#E5E5E5",
    backgroundColor: "rgba(229, 229, 229, 0.6)",
    background: "#FFFFFF",
    senderBackground: "#FFFFFF", //chat sender background color
    receiverBackground: "#505050", //chat receiver background color
    personalBackground: "#211551", //chat sender/receiver background color
    senderBackgroundText: "#000000", //chat sender text background color
    receiverBackgroundText: "#FFFFFF", //chat receiver text background color
    personalBackgroundText: "#FFFFFF", //chat sender/receiver text background color
  },
  soulcycleDigital: {
    theme: "dark",
    primaryColor: "#FFDE00",
    chatCountBackgroundColor: "#FFDE00",
    secondaryColor: "#424242",
    chatTextColor: "#424242",
    tertioryColor: "#FFDE00",
    primaryTextColor: "#FFFFFF",
    secondaryTextColor: "#FFFFFF",
    chatBackgroudColor: "#FFFFFF",
    boxBorderColor: "#A6A6A6",
    layerOne: "#E5E5E5",
    backgroundColor: "rgba(12, 12, 12, 0.5)",
    background: "#FFFFFF",
    senderBackground: "#FFFFFF", //chat sender background color
    receiverBackground: "#505050", //chat receiver background color
    personalBackground: "#FFDE00", //chat sender/receiver background color
    senderBackgroundText: "#000000", //chat sender text background color
    receiverBackgroundText: "#FFFFFF", //chat receiver text background color
    personalBackgroundText: "#FFFFFF", //chat sender/receiver text background color
  },
  therippleclub: {
    theme: "light",
    primaryColor: "#52C6D8",
    chatCountBackgroundColor: "#52C6D8",
    secondaryColor: "#D9CEB4",
    chatTextColor: "#424242",
    tertioryColor: "#D9CEB4",
    primaryTextColor: "#FFFFFF",
    secondaryTextColor: "#505050",
    chatBackgroudColor: "#505050",
    boxBorderColor: "#A6A6A6",
    layerOne: "#E5E5E5",
    backgroundColor: "rgba(229, 229, 229, 0.6)",
    background: "#FFFFFF",
    senderBackground: "#FFFFFF", //chat sender background color
    receiverBackground: "#505050", //chat receiver background color
    personalBackground: "#52C6D8", //chat sender/receiver background color
    senderBackgroundText: "#000000", //chat sender text background color
    receiverBackgroundText: "#FFFFFF", //chat receiver text background color
    personalBackgroundText: "#FFFFFF", //chat sender/receiver text background color
  },
  tribe: {
    theme: "dark",
    primaryColor: "#F22B5D",
    chatCountBackgroundColor: "#F22B5D",
    progressBarColor: "#ffffff",
    secondaryColor: "#424242",
    chatTextColor: "#FFFFFF",
    tertioryColor: "#594AD7",
    primaryTextColor: "#FFFFFF",
    secondaryTextColor: "#FFFFFF",
    chatBackgroudColor: "#FFFFFF",
    boxBorderColor: "#767676",
    layerOne: "#222222",
    backgroundColor: "rgba(0, 0, 0, 0.6)",
    background: "#222222",
    senderBackground: "#FFFFFF", //chat sender background color
    receiverBackground: "#505050", //chat receiver background color
    personalBackground: "#F22B5D", //chat sender/receiver background color
    senderBackgroundText: "#000000", //chat sender text background color
    receiverBackgroundText: "#FFFFFF", //chat receiver text background color
    personalBackgroundText: "#FFFFFF", //chat sender/receiver text background color
  },
  "1stPhorm": {
    theme: "dark",
    primaryColor: "#01A6D3",
    chatCountBackgroundColor: "#01A6D3",
    secondaryColor: "#424242",
    chatTextColor: "#424242",
    tertioryColor: "#787878",
    primaryTextColor: "#FFFFFF",
    secondaryTextColor: "#FFFFFF",
    chatBackgroudColor: "#FFFFFF",
    boxBorderColor: "#767676",
    layerOne: "#222222",
    backgroundColor: "rgba(0, 0, 0, 0.6)",
    background: "#222222",
    senderBackground: "#FFFFFF", //chat sender background color
    receiverBackground: "#505050", //chat receiver background color
    personalBackground: "#01A6D3", //chat sender/receiver background color
    senderBackgroundText: "#000000", //chat sender text background color
    receiverBackgroundText: "#FFFFFF", //chat receiver text background color
    personalBackgroundText: "#FFFFFF", //chat sender/receiver text background color
  },
  watchfit: {
    theme: "dark",
    primaryColor: "#FA7C01",
    chatCountBackgroundColor: "#FA7C01",
    progressBarColor: "#767676",
    secondaryColor: "#424242",
    tertioryColor: "#53D0D4",
    primaryTextColor: "#FFFFFF",
    secondaryTextColor: "#FFFFFF",
    chatBackgroudColor: "#FFFFFF",
    boxBorderColor: "#767676",
    layerOne: "#222222",
    backgroundColor: "rgba(0, 0, 0, 0.6)",
    background: "#222222",
    senderBackground: "#FFFFFF", //chat sender background color
    receiverBackground: "#505050", //chat receiver background color
    personalBackground: "#FA7C01", //chat sender/receiver background color
    senderBackgroundText: "#000000", //chat sender text background color
    receiverBackgroundText: "#FFFFFF", //chat receiver text background color
    personalBackgroundText: "#FFFFFF", //chat sender/receiver text background color
  },
  theeLittleBird: {
    theme: "light",
    primaryColor: "#004AAD",
    chatCountBackgroundColor: "#004AAD",
    progressBarColor: "#004AAD",
    secondaryColor: "#E5E5E5",
    tertioryColor: "#63BCC9",
    primaryTextColor: "#FFFFFF",
    secondaryTextColor: "#505050",
    chatBackgroudColor: "#FFFFFF",
    chatTextColor: "#424242",
    boxBorderColor: "#A6A6A6",
    layerOne: "#E5E5E5",
    backgroundColor:
      "linear-gradient(0deg, rgba(229, 229, 229, 0.6), rgba(229, 229, 229, 0.6)),linear-gradient(0deg, #ffffff99, #ffffff99)",
    background: "#FFFFFF",
    senderBackground: "#FFFFFF", //chat sender background color
    receiverBackground: "#505050", //chat receiver background color
    personalBackground: "#004AAD", //chat sender/receiver background color
    senderBackgroundText: "#000000", //chat sender text background color
    receiverBackgroundText: "#FFFFFF", //chat receiver text background color
    personalBackgroundText: "#FFFFFF", //chat sender/receiver text background color
  },

  engivio: {
    theme: "light",
    primaryColor: "#15B1D7",
    chatCountBackgroundColor: "#15B1D7",
    progressBarColor: "#15B1D7",
    secondaryColor: "#CAEEF9",
    tertioryColor: "#63BCC9",
    primaryTextColor: "#FFFFFF",
    secondaryTextColor: "#505050",
    chatBackgroudColor: "#FFFFFF",
    chatTextColor: "#424242",
    boxBorderColor: "#A6A6A6",
    layerOne: "#CAEEF9",
    backgroundColor:
      "linear-gradient(0deg, rgba(229, 229, 229, 0.6), rgba(229, 229, 229, 0.6)),linear-gradient(0deg, #ffffff99, #ffffff99)",
    background: "#FFFFFF",
    senderBackground: "#FFFFFF", //chat sender background color
    receiverBackground: "#505050", //chat receiver background color
    personalBackground: "#15B1D7", //chat sender/receiver background color
    senderBackgroundText: "#000000", //chat sender text background color
    receiverBackgroundText: "#FFFFFF", //chat receiver text background color
    personalBackgroundText: "#FFFFFF", //chat sender/receiver text background color
  },

};

const setColorData = (el, themeData) => {
  if (el.name === "--moreSection-color") {
  }
  if (CheckValue(themeData[el.value])) {
    document.documentElement.style.setProperty(el.name, themeData[el.value]);
    setTimeout(() => {
      themeColor[el.value] = getComputedStyle(
        document.documentElement
      ).getPropertyValue(el.name);
    }, 100);
  }
};

export const setColor = async (themeData) => {
  await [
    { name: "--theme-color", value: "theme" },
    { name: "--primary-color", value: "primaryColor" },
    { name: "--progressBar-color", value: "progressBarColor" },
    { name: "--secondary-color", value: "secondaryColor" },
    { name: "--chat-text-color", value: "chatTextColor" },
    {
      name: "--chat-count-background-color",
      value: "chatCountBackgroundColor",
    },
    { name: "--tertiory-color", value: "tertioryColor" },
    { name: "--primary-text-color", value: "primaryTextColor" },
    { name: "--secondary-text-color", value: "secondaryTextColor" },
    { name: "--chat-background-color", value: "chatBackgroudColor" },
    { name: "--box-border-color", value: "boxBorderColor" },
    { name: "--layer-one", value: "layerOne" },
    { name: "--background-color", value: "backgroundColor" },
    { name: "--background", value: "background" },
    { name: "--sender-background", value: "senderBackground" },
    { name: "--receiver-background", value: "receiverBackground" },
    { name: "--personal-background", value: "personalBackground" },
    { name: "--sender-background-text", value: "senderBackgroundText" },
    { name: "--receiver-background-text", value: "receiverBackgroundText" },
    { name: "--personal-background-text", value: "personalBackgroundText" },
  ].forEach((el) => {
    setColorData(el, themeData);
  });
};

export const themeColor = {
  theme: "dark",
  primaryColor: "#F22B5D",
  secondaryColor: "#424242",
  chatCountBackgroundColor: "#F22B5D",
  chatTextColor: "#424242",
  tertioryColor: "#594AD7",
  primaryTextColor: "#FFFFFF",
  secondaryTextColor: "#FFFFFF",
  chatBackgroudColor: "#FFFFFF",
  boxBorderColor: "#767676",
  layerOne: "#222222",
  backgroundColor: "rgba(0, 0, 0, 0.6)",
  background: "#222222",
};

export const workoutData1 = {
  workout_builder: [
    {
      segment_id: "sidFYEH16781740095390000",
      segment_order: "1",
      segment_icon: "Barre",
      segment_icon_id: "null",
      segment_icon_url: "null",
      segment_icon_svg_url: "null",
      segment_name: "nbnb",
      segment_duration_in_seconds: 324,
      exercise_ids: ["eidFYEH167817400953900000"],
      is_activity_segment_separated: false,
      exercises: [
        {
          segment_id: "sidFYEH16781740095390000",
          exercise_id: "eidFYEH167817400953900000",
          exercise_name: "nnb",
          exercise_audio: [
            {
              media_id: "3e03e92e-839e-45aa-bf2a-50d00bf702f2",
              audio_url:
                "https://dev-studio-content.s3.amazonaws.com/2dabf70c-904b-4dee-8aa7-4d539399a216/media-library/audios/3e03e92e-839e-45aa-bf2a-50d00bf702f2/3e03e92e-839e-45aa-bf2a-50d00bf702f2.mp3",
              media_name: "file_example_MP3_5MG",
              audio_play_at: 60,
              audio_duration: 0,
              is_coach_muted: false,
              audio_play_duration: 144,
              is_audio_play_in_loop: true,
            },
          ],
          exercise_image: null,
          exercise_order: "1",
          exercise_video: [
            {
              media_id: "12b7ff99-be26-4f95-9490-276522817370",
              video_url:
                "https://dev-studio-content.s3.amazonaws.com/2dabf70c-904b-4dee-8aa7-4d539399a216/media-library/videos/12b7ff99-be26-4f95-9490-276522817370/hls/12b7ff99-be26-4f95-9490-276522817370/master.m3u8",
              media_name: "testing",
              video_play_at: 30,
              is_video_muted: true,
              video_duration: 0,
              video_play_duration: 240,
              is_video_play_in_loop: true,
              video_screen_location: "FULL_SCREEN",
            },
          ],
          show_countdown: true,
          show_to_participants: false,
          current_activity_status: "ON_DEMAND",
          exercise_duration_in_seconds: 324,
          is_activity_exercise_separated: false,
        },
      ],
    },
  ],
};
