import React from 'react';
import './FormInput.scss';
import { CheckValue } from '../../../services/CommonFunction';
export const inputEvent = (e, status = '',value) => {
    if (e.which === 32 && !e.target.value.length) {
        e.preventDefault();
    } else if (status === 'NumberTime') {
        if (e.which < 48 || e.which > 57) {
            e.preventDefault();
        }
    } else if (status === 'NumberMinute') {
        if(value.length === 1 ) {
            // if(parseInt(value) > 5) {
            //     // e.preventDefault();
            // }
        } else if (e.which < 48 || e.which > 57) {
            e.preventDefault();
        }
    } else if (status === 'number') {
        if (e.which < 48 || e.which > 57) {

            e.preventDefault();
        }
    } else if (status === 'string') {
        if ((e.which > 96 && e.which < 123) || (e.which > 64 && e.which < 91) || e.which === 32) {        
        } else {
            e.preventDefault();
        }
    } else if (status === 'gymCode') {
        if (!e.target.value.length) {
            if((e.which > 96 && e.which < 123) || (e.which > 64 && e.which < 91)) {
            } else {
                e.preventDefault();
            }
        } else {
            if((e.which > 47 || e.which < 58) || (e.which > 96 && e.which < 123) || (e.which > 64 && e.which < 91)) {

            } else {
                e.preventDefault();
            }
        }
    }
}

export const TextInput = ({ handler, touched, hasError, meta, valid,invalid,validator,errors, ...props }) => {
    const type = meta.type && (meta.type !== 'string' && meta.type !== 'gymCode' && meta.type !== 'number')?meta.type:'text';
    const touch = (CheckValue(meta.touched)?meta.touched:false) || touched;
    return (
    <React.Fragment>
        {meta.label !== "" ?
            <label>
                {meta.label}
            </label> : null}
        <div className='input_outer'>
            <input readOnly={meta.readOnly || false} className={`formInput${touch && invalid?' inputError':''}`} autoComplete="false" type={type} maxLength={meta.maxLength ? meta.maxLength : ''} onKeyPress={(el) => inputEvent(el, meta.type || 'text')} placeholder={`${meta.placeholder}`} {...handler()} />
        </div>
    </React.Fragment>
)};
export const CheckboxInput = ({ handler, touched, hasError, meta, valid,invalid,validator,errors, ...props }) => {
    const type = meta.type && (meta.type !== 'string' && meta.type !== 'gymCode' && meta.type !== 'number')?meta.type:'text';
    const touch = (CheckValue(meta.touched)?meta.touched:false) || touched;
    return (
    <React.Fragment>
        {meta.label !== "" ?
            <label>
                {meta.label}
            </label> : null}
        <div className='input_outer'>
            <input readOnly={meta.readOnly || false} className={`formInput${touch && invalid?' inputError':''}`} autoComplete="false" type={type} maxLength={meta.maxLength ? meta.maxLength : ''} onKeyPress={(el) => inputEvent(el, meta.type || 'text')} placeholder={`${meta.placeholder}`} {...handler()} />
        </div>
    </React.Fragment>
)}