import React, { useState } from 'react';
import './HideUnhideDiv.scss';
import { SVG, selectIcon } from '../../../components/UI/selector';
import { themeColor } from '../../../services/Constants';

const HideUnhideDiv = ({ clas, clasType, divid = "", callback = Function(), ...props  }) => {
    const [hide, setHide] = useState(false);
    const theme = themeColor.theme;
    return (
        <React.Fragment>
            <div className={`${clasType} hideUnhideDiv${hide ? ' active' : ''}`} onClick={() => {
                const status = !hide;
                setHide(status);
                callback(status);
            }}>
                <SVG className={theme === "light" ? 'lightSvg' : 'darkSvg'} src={selectIcon('arrowIcon')} />
            </div>
            <div id={divid} className={`${clas} ${clasType}${hide ? ' hide' : ''}`} >
                {props.children}
            </div>
        </React.Fragment>
    )
}

export default HideUnhideDiv;