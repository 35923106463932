import React from 'react';
import './ToolTips.scss';

class ToolTips extends React.Component {
    constructor(props) {
      super(props);
  
      this.state = {
        displayTooltip: false,
        random: UniqueID(),
        element: null,
        overflow: true
      };
  
      this.hideTooltip = this.hideTooltip.bind(this);
      this.showTooltip = this.showTooltip.bind(this);
    }

    componentDidMount() {
      this.setEventListner('addEventListener');
      // if(CheckValue(this.props.overflow) && this.props.overflow) {
      //   const { width } = this.ele.parentElement.getBoundingClientRect();
      //   const textWidth = CheckWidth(this.child.textContent,0);
      //   this.setState({ overflow: textWidth > width});
      // }
    }

    setEventListner = (status) => {
      this.child[status]('mouseleave',this.hideTooltip);
      this.child[status]('mouseover',this.showTooltip);
    }

    get child () {
      return this.ele.firstChild instanceof HTMLElement?this.ele.firstChild:this.ele;
    };

    get ele () {
      return document.getElementById(this.state.random);
    }

    componentWillUnmount() {
      this.hideTooltip();
      this.setEventListner('removeEventListener');
    }
    hideTooltip() {
      if(this.props.showTooltip) {
        if(CheckValue(this.state.element)) {
          document.body.removeChild(this.state.element);
        }
        this.setState({ displayTooltip: false, element: null });
      }
    }

    checkOverFlow = () => {
      if(CheckValue(this.props.overflow) && this.props.overflow) {
        const { width } = this.child.getBoundingClientRect();
        // const { width } = this.ele.parentElement.getBoundingClientRect();
        // const textWidth = this.ele.getBoundingClientRect().width;
        const textWidth = CheckWidth(this.child);
        return textWidth > Math.round(width);
      }
      return true
    }
    showTooltip() {
      if(!this.state.displayTooltip && this.props.showTooltip && this.checkOverFlow()) {
        // let position = this.props.position || 'left';
        let rect = this.child.getBoundingClientRect();
        this.toolTip(this.props.position || 'left',rect);

      }
    }

    toolTip = (position,rect) => {
      // if(window.innerWidth - rect.x < 250) {
      //   this.toolTip('left',rect);
      //   return;
      // } else if(rect.x < 250) {
      //   this.toolTip('right',rect);
      //   return;
      // }

      const div = document.createElement('div');
      div.className = `tooltips-bubble tooltips-${position}`;
      div.style.opacity='0';
      div.style.zIndex='-10';
      document.body.appendChild(div);

      const div1 = document.createElement('div');
      div1.className = `tooltip-message`;
      div1.innerHTML = `${this.props.message}`;
      div.appendChild(div1);

      if(this.props.title) {
        const span = document.createElement('span');
        span.innerText= this.props.title;
        span.className = 'tooltip_message_span';
        div1.prepend(span);
      }

      let top = rect.top + window.scrollY;
      let left = rect.left + window.scrollX;
      
      const divRect = div.getBoundingClientRect();

      setTimeout(() => {
        if(position === 'top') {
          const temp = div.getBoundingClientRect();
          const tempTop = top - (temp.height - divRect.height);
          div.style.top = tempTop+'px';
        }
        div.style.opacity='1';
        div.style.zIndex='9999';
      },1);
      if(position === 'right') {
        top += rect.height/2;
        left += rect.width;
      } else if(position === 'top') {
        top -= divRect.height;
        left += rect.width/2;
      } else if(position === 'bottom') {
        top += rect.height;
        left += rect.width/2;
      } else {
        top += rect.height/2;
        left -= divRect.width;
      }

      div.style.left = left+'px';
      div.style.top = top+'px';

      this.setState({ displayTooltip: true, element: div });

    }
  
    render() {
        // let message = this.props.message;
        // let position = this.props.position || 'left';
        const { meta_id, className, children, style } = this.props;
        const { random } = this.state;
        return (
            // <span className='tooltips' onMouseLeave={this.hideTooltip}>
              // <span className='tooltip-trigger' id={this.state.random} onMouseOver={event => show?this.showTooltip(event):null}>
              <span meta-id={meta_id || random} style={style} className={`tooltip-trigger ${className || ''}`} id={ random }>
                {children}
              </span>
            // </span>
        );
    }
}

export default ToolTips

const UniqueID = () => Math.floor((1 + Math.random()) * 0x10000).toString(16).substring(1);

const CheckValue = (value) => value !== null && value !== undefined && value !== "null" && value !== "undefined";

const CheckWidth = (ele = null) => {
  if(ele !== null) {
    const value = ele.textContent;
    const clas = (ele.classList.length>1?ele.classList[1]:"")+' valueCheck';
    if (value !== '') {
      const div = document.createElement('span');
      div.innerText = value;
      div.className = clas;
      div.style.whiteSpace = 'nowrap';
      div.style.width = 'fit-content';
      ele.parentElement.appendChild(div);
      const width1 = div.clientWidth;
      ele.parentElement.removeChild(div);
      return Math.round(width1)
    }
  }
  return 0;
}