import React, { Component } from "react";
import { CheckValue } from "../../../services/CommonFunction";
import {
  BoardContainerWaitingRoom,
  CenterCoachProfile,
  CurrentUser,
  Footer,
  Logo,
  PinUser,
  StopWatch,
} from "../ChildComponentUI/ChildComponentUI";
import "./WaitingRoom.scss";
import { changeVolume } from "../../../components/UI/InputRange/InputRange";

class WaitingRoom extends Component {
  constructor(props) {
    super(props);
    this.state = {
      firstTime: false,
      value: 100,
      id: "meeting-audio",
    };
    this.chatRef = React.createRef();
    this.paginationRef = React.createRef();
  }

  componentDidMount() {
    if (CheckValue(localStorage.getItem("setVolume"))) {
      changeVolume(
        parseInt(localStorage.getItem("setVolume"), 10),
        this.state.id,
        "null"
      );
    } else {
      changeVolume(100, this.state.id, "null");
    }
  }

  componentWillUnmount() {}

  statsClick = (status) => {
    const { zoom } = this.props;
    if (zoom && (status === "audio" || status === "camera")) {
      zoom.enableDisableAudioCamera(status);
    }
  };

  render() {
    const {
      totalSeconds,
      canvas,
      allUsers,
      tagUsers,
      webPreviewOff,
      required,
      workoutData,
      coachData,
      selfData,
      userPin,
      zoom,
      zoomData,
    } = this.props;

    const { value, id } = this.state;
    const isVideoStarted = coachData.host
      ? coachData.isVideoStarted
      : coachData.owner_isVideoStarted;

    return (
      <section className="newCasting">
        {!isVideoStarted ? <CenterCoachProfile {...coachData} /> : null}
        {canvas}
        <div className="waiting_header">
          <Logo logo_thumb={workoutData.logo_thumb} />
          <StopWatch duration={totalSeconds < 0 ? 0 : totalSeconds} />
        </div>
        <PinUser tagUsers={tagUsers} allUsers={allUsers} userPin={userPin} />
        <BoardContainerWaitingRoom
          {...this.props}
          height={zoomData.height}
          width={zoomData.width}
          userPin={userPin}
          chatRef={this.chatRef}
          paginationRef={this.paginationRef}
          zoom={zoom}
        />
        {/* {zoomData && zoomData.activeSpeaker ? (
          <CurrentUser speaker={zoomData.activeSpeaker} allUsers={allUsers} />
        ) : null} */}
        <Footer
          webPreviewOff={webPreviewOff}
          id={id}
          value={value}
          required={required}
          statsClick={this.statsClick}
          zoom={zoom}
          zoomData={zoomData}
          selfData={selfData}
        />
      </section>
    );
  }
}

export default WaitingRoom;
