import React from 'react';
import './FormGroups.scss';

const FormGroups = (props) => {
    return (
        <div className={`form_group ${props.clas || ''}`} onClick={props.click || null} id={props.id  || null} style={props.style || {}}>
            {props.children}
        </div>
    );

};

const FormGroupRow = (props) => {

    return (
        <div className="form_row">
            {props.children}
        </div>
    );

};

export const FormGroupRow3 = (props) => {

    return (
        <div className="form_row3">
            {props.children}
        </div>
    );

};

export const FormGroupRows = (props) => {

    return (
        <div className="form_rows">
            {props.children}
        </div>
    );

};

const CustomCheckbox = (props) => {

    return (
        <div className="role_content">
            <label className="role_container">
                <input value={props.value} style={{ display: 'none' }} onChange={props.change}
                    checked={props.checked} type="checkbox" />
                <span className="checkmark"></span>
            </label>
            <div className="checkbox_text">
                <p style={{
                    cursor: props.titleClick === undefined ? 'unset' : 'pointer',
                    color: props.titleClick === undefined ? '#FFFFFF' : '#F22B5D'
                }}
                    onClick={props.titleClick} className="role_title">{props.title}</p>
                <p className="role_subtitle">{props.subtitle}</p>
            </div>
        </div>
    )

}



export {
    FormGroups,
    FormGroupRow,
    CustomCheckbox
}

