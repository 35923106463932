import { Subject } from 'rxjs';
import { selectIcon } from '../components/UI/selector';

const subject = new Subject();
const toasterSubject = new Subject();
const ivsSubject = new Subject();
const headerFilterSubject = new Subject();
const gymIDSubject = new Subject();
const stopWorkoutSubject = new Subject();
const stopActivitySubject = new Subject();
const changeActivityView = new Subject();
const addRoomSubject = new Subject();
const logOutSubject = new Subject();
const seekVideoSubject = new Subject();
const seekCompleteSubject = new Subject();
const bufferingSubject = new Subject();
const playerPlaySubject = new Subject();
const modalSubject = new Subject();

export const modalPopup = {
    cross: true,
    color: '#F22B5D'
}

const roomData = {
    detail: {},
    sendData: data => roomData.detail = { ...data }
};

const logOut = {
    status: false,
    setLogOut: data => logOut.status = data
};

const gymData = {
    detail: {},
    sendData: data => gymData.detail = { ...data }
};


const headerFilterSubscriber = {
    send: data => headerFilterSubject.next({ data: data }),
    get: () => headerFilterSubject.asObservable()
}

const seekVideoSubscriber = {
    send: data => seekVideoSubject.next({ data: data }),
    get: () => seekVideoSubject.asObservable()
}

const seekCompleteSubscriber = {
    send: data => seekCompleteSubject.next({ data: data }),
    get: () => seekCompleteSubject.asObservable()
}

const bufferingSubscriber = {
    send: data => bufferingSubject.next({ data: data }),
    get: () => bufferingSubject.asObservable()
}

const playerPlaySubscriber = {
    send: data => playerPlaySubject.next({ data: data }),
    get: () => playerPlaySubject.asObservable()
}


const userData = {
    detail: {},
    sendData: data => userData.detail = { ...data }
};

const showPassword = {
    status: false,
    changeStatus: data => {
        // console.log(data);
        showPassword.status = data;
    },
    getStatus: () => showPassword.status
};

export const modalService = {
    sendModalMessage: message => modalSubject.next(message),
    getModalMessage: () => modalSubject.asObservable()
}

const messageService = {
    showLoader: true,
    background: false,
    text: null,
    count: [],
    sendMessage: async (message) => {
        if(message) {
            await messageService.count.push(message);
        } else {
            await messageService.count.pop();
        }
        if(!messageService.count.length && messageService.background) {
            messageService.background = false;
        }
        // console.log(messageService.count.length);
        subject.next({ show: messageService.count.length > 0})
    },
    getMessage: () => subject.asObservable()
}

const ivsSubscriber = {
    sendIvsData: data => ivsSubject.next({ data: data }),
    getIvsData: () => ivsSubject.asObservable()
}

const toasterSubscriber = {
    sendToasterData: data => toasterSubject.next({ data: data }),
    getToasterData: () => toasterSubject.asObservable()
}

const stopWorkoutService = {
    sendStopMessage: message => stopWorkoutSubject.next({ data: message }),
    getStopMessage: () => stopWorkoutSubject.asObservable()
}

const stopActivityService = {
    sendStopMessage: message => stopActivitySubject.next({ data: message }),
    getStopMessage: () => stopActivitySubject.asObservable()
}

const activityChangeView = {
    sendChangeView: message => changeActivityView.next({ data: message }),
    getChangeView: () => changeActivityView.asObservable()
}

const addRoomService = {
    sendStopMessage: message => addRoomSubject.next({ data: message }),
    getStopMessage: () => addRoomSubject.asObservable()
}

const logOutService = {
    sendStopMessage: message => logOutSubject.next({ data: message }),
    getStopMessage: () => logOutSubject.asObservable()
}

const gymIDService = {
    gymID: {
        id: '',
        name: '',
        code: '',
        coachId: ''
    },
    sendGymID: data => {
        gymIDService.gymID = { ...data };
        return gymIDSubject.next({ gymID: data })
    },
    getGymID: () => gymIDSubject.asObservable()
}

const pattern = {
    memberShip: '^[a-zA-Z]{4}[0-9]{4}',
    email: /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/,
    username: /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/
}

const imageError = (event) => {
    event.target.onerror = null;
    event.target.src = selectIcon('userDummy');
}

const imageErrors = (status, event) => {
    // console.log(status,event);
    event.target.onerror = null;
    event.target.src = selectIcon(status);
}

export {
    logOut,
    messageService,
    gymIDService,
    showPassword,
    toasterSubscriber,
    stopActivityService,
    activityChangeView,
    addRoomService,
    userData,
    gymData,
    roomData,
    pattern,
    imageError,
    imageErrors,
    headerFilterSubscriber,
    stopWorkoutService,
    logOutService,
    ivsSubscriber,
    seekVideoSubscriber,
    seekCompleteSubscriber,
    bufferingSubscriber,
    playerPlaySubscriber
};