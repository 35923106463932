import SVG from 'react-inlinesvg';

import ActivityDoneImg from '../../assets/Images/activity-done.png';
import { faHeart } from '@fortawesome/free-solid-svg-icons';
import userDummy from '../../assets/Images/dummy.jpg';
import calorieIcon from '../../assets/Images/calorie.svg';
import timerIcon from '../../assets/Images/timer.svg';
import verified from '../../assets/Images/icn_verified.svg';
import userCover from '../../assets/Images/userCover.png';
import Bitmap from '../../assets/Images/Bitmap.png';
import DefaultImage from '../../assets/Images/Bitmap.svg';

// ====================HEADER  ICONS =======================
import PlayIcon from '../../assets/Images/header_play.png';
import StopIcon from '../../assets/Images/header_stop.png';
// =========================================================

// ==================== SPECIALISIMS ==============
import Running from '../../assets/Images/Running.png';
import Dance from '../../assets/Images/Dance.png';
import Postnatal from '../../assets/Images/Postnatal.png';
import Sports from '../../assets/Images/Sports.png';
import Stretch from '../../assets/Images/Stretch.png';
import Strength from '../../assets/Images/Strength.png';
import Prenatal from '../../assets/Images/Prenatal.png';
import Motivation from '../../assets/Images/Motivation.png';
import Pilates from '../../assets/Images/Pilates.png';
import Boxing from '../../assets/Images/Boxing.png';
import Spinning from '../../assets/Images/Spinning.png';
import Medication from '../../assets/Images/Medication.png';
import Bootcamp from '../../assets/Images/Bootcamp.png';
import Core from '../../assets/Images/Core.png';
import Barre from '../../assets/Images/Barre.png';
import Rowing from '../../assets/Images/Rowing.png';
import Martial from '../../assets/Images/Martial.png';
import Elliptical from '../../assets/Images/Elliptical.png';
import Yoga from '../../assets/Images/Yoga.png';
import checkImage from '../../assets/Images/checkImage.png';
import rankIcon from '../../assets/Images/rankIcon.png';
import redVideoIcon from '../../assets/Images/redVideoIcon.png';
import cameraFilled from '../../assets/Images/cameraFilled.svg';
import cameraBlank from '../../assets/Images/cameraBlank.svg';
import fullScreen from '../../assets/Images/fullScreen1.png';
import exitFullscreen from '../../assets/Images/exitFullscreen.png';
import scanGlobe from '../../assets/Images/scanGlobe.png';
import scanWifi from '../../assets/Images/scanWifi.png';
import caloriesIcon from '../../assets/Images/caloriesIcon.png';
import calorieIcon2x from '../../assets/Images/calorieIcon2x.png';

// =========================================================

import blackImage from '../../assets/Images/black.png';
import seekBack from '../../assets/Images/seekBack.png';
import seekForward from '../../assets/Images/seekForward.png';
import videoPause from '../../assets/Images/videoPause.png';
import videoPlay from '../../assets/Images/videoPlay.png';
import volumeMute from '../../assets/Images/volumeMute.png';
import volumeUp from '../../assets/Images/volumeUp.png';
import coach2 from '../../assets/Images/coach2.png';
import sendMessage from '../../assets/Images/sendMessage.svg';
import everyone from '../../assets/Icon/member_icon.svg';
import owner from '../../assets/Images/owner.png';
import chatIcon from '../../assets/Images/chatIcon.png';

import chatIcon1 from '../../assets/Icon/Chat.svg';
import memberIcon1 from '../../assets/Icon/member_icon.svg';
import pinIcon from '../../assets/Icon/pin_icon.svg';
import arrowIcon from '../../assets/Icon/arrow_icon.svg';
import audio from '../../assets/Icon/audio.svg';
import noaudio from '../../assets/Icon/noAudio.svg';
import video from '../../assets/Icon/video.svg';
import novideo from '../../assets/Icon/noVideo.svg';
import videoPng from '../../assets/Icon/video.png';
import novideoPng from '../../assets/Icon/noVideo.png';
import volume from '../../assets/Icon/volume.svg';
import novolume from '../../assets/Icon/novolume.svg';
import heartIcon from '../../assets/Icon/heart_icon.svg';
import videoIcon from '../../assets/Icon/video_icon.svg';
import calories_icon from '../../assets/Icon/calories_icon.svg';
import calories_icon1 from '../../assets/Icon/calories_icon1.png';
import rank_icon from '../../assets/Icon/rank_icon.svg';
import video_icon from '../../assets/Icon/videoIcon.svg';
import full_screen from '../../assets/Icon/full_screen.svg';
import exit_full_screen from '../../assets/Icon/exit_full_screen.svg';
import castIcon from '../../assets/Icon/cast_icon.svg';
import screenIcon from '../../assets/Icon/screen_icon.svg';

// Activity Type
import barre from '../../assets/activityType/barre.svg';
import bootcamp from '../../assets/activityType/bootcamp.svg';
import boxing from '../../assets/activityType/boxing.svg';
import Calisthenics from '../../assets/activityType/calisthenics.svg';
import cardio from '../../assets/activityType/cardio.svg';
import celebrity from '../../assets/activityType/celebrity.svg';
import core from '../../assets/activityType/core.svg';
import cycling from '../../assets/activityType/cycling.svg';
import dance from '../../assets/activityType/dance.svg';
import Functional from '../../assets/activityType/functional.svg';
import hiit from '../../assets/activityType/hiit.svg';
import meditation from '../../assets/activityType/meditation.svg';
import mobility from '../../assets/activityType/mobility.svg';
import other from '../../assets/activityType/other.svg';
import pilates from '../../assets/activityType/pilates.svg';
import seniorFitness from '../../assets/activityType/seniorFitness.svg';
import sports from '../../assets/activityType/sports.svg';
import streching from '../../assets/activityType/streching.svg';
import strengths from '../../assets/activityType/strength.svg';
import toning from '../../assets/activityType/toning.svg';
import yoga from '../../assets/activityType/yoga.svg';

import swapIcon from '../../assets/Icon/swap_icon.svg';
import moreIcon from '../../assets/Icon/more_icon.svg';
import stopVideoIcon from '../../assets/Icon/stopVideo_icon.svg';
import startVideoIcon from '../../assets/Icon/startVideo_icon.svg';
import moreOptionBackground from '../../assets/Icon/moreOption_background.svg';
import stopCasting from '../../assets/Icon/stopCasting.svg';

const selectIcon = (icon) => {
    switch (icon) {
        case "stopCasting":
            return stopCasting;
        case "moreOptionBackground":
            return moreOptionBackground;
        case "startVideoIcon":
            return startVideoIcon;
        case "stopVideoIcon":
            return stopVideoIcon;
        case "moreIcon":
            return moreIcon;
        case "swapIcon":
            return swapIcon;
        case "screenIcon":
            return screenIcon;
        case "castIcon":
            return castIcon;
        case "video_icon":
            return video_icon;
        case "rank_icon":
            return rank_icon;
        case "calories_icon":
            return calories_icon;
        case "calories_icon1":
            return calories_icon1;
        case "full_screen":
            return full_screen;
        case "exit_full_screen":
            return exit_full_screen;
        case "videoIcon":
            return videoIcon;
        case "heartIcon":
            return heartIcon;
        case "chatIcon1":
            return chatIcon1;
        case "memberIcon1":
            return memberIcon1;
        case "pinIcon":
            return pinIcon;
        case "arrowIcon":
            return arrowIcon;
        case "audio":
            return audio;
        case "noaudio":
            return noaudio;
        case "videoPng":
            return videoPng;
        case "novideoPng":
            return novideoPng;
        case "video":
            return video;
        case "novideo":
            return novideo;
        case "volume":
            return volume;
        case "novolume":
            return novolume;

        case 'Running':
            return Running;
        case 'Dance':
            return Dance;
        case 'Sports':
            return Postnatal;
        case 'Spinning':
            return Sports;
        case 'Outdoors':
            return Stretch;
        case 'HIIT':
            return Strength;
        case 'Prenatal':
            return Prenatal;
        case 'Motivation':
            return Motivation;
        case 'Pilates':
            return Pilates;
        case 'Boxing':
            return Boxing;
        case 'Cycling':
            return Spinning;
        case 'Medication':
            return Medication;
        case 'Bootcamp':
            return Bootcamp;
        case 'Core':
            return Core;
        case 'Barre':
            return Barre;
        case 'Rowing':
            return Rowing;
        case 'userCover':
            return userCover;
        case 'Martial Arts':
            return Martial;
        case 'Elliptical':
            return Elliptical;
        case 'Yoga':
            return Yoga;
        case 'calories':
            return calorieIcon;
        case 'DefaultImage':
            return DefaultImage;
        case 'heart':
            return faHeart;
        case "activity":
            return ActivityDoneImg;
        case "Bitmap":
            return Bitmap;
        case "header_play":
            return PlayIcon;
        case "header_pause":
            return PlayIcon;
        case "header_stop":
            return StopIcon;
        case "timer":
            return faHeart;
        case "calorieIcon":
            return calorieIcon;
        case "timerIcon":
            return timerIcon;
        case "userDummy":
            return userDummy;
        case "verified":
            return verified;
        case "checkImage":
            return checkImage;
        case "black":
            return blackImage;
        case "rankIcon":
            return rankIcon;
        case "redVideoIcon":
            return redVideoIcon;
        case "cameraFilled":
            return cameraFilled;
        case "cameraBlank":
            return cameraBlank;
        case "fullScreen":
            return fullScreen;
        case "exitFullscreen":
            return exitFullscreen;
        case "scanGlobe":
            return scanGlobe;
        case "scanWifi":
            return scanWifi;
        case "seekBack":
            return seekBack;
        case "seekForward":
            return seekForward;
        case "videoPause":
            return videoPause;
        case "videoPlay":
            return videoPlay;
        case "volumeMute":
            return volumeMute;
        case "volumeUp":
            return volumeUp;
        case "caloriesIcon":
            return caloriesIcon;
        case "calorieIcon2x":
            return calorieIcon2x;
        case "coach2":
            return coach2;
        case "everyone":
            return everyone;
        case "sendMessage":
            return sendMessage;
        case "chatIcon":
            return chatIcon;
        case "owner":
            return owner;

        // Activity Type
        case "barre":
            return barre;
        case "bootcamp":
            return bootcamp;
        case "boxing":
            return boxing;
        case 'calisthenics':
            return Calisthenics;
        case "cardio":
            return cardio;
        case "celebrity":
            return celebrity;
        case "core":
            return core;
        case "cycling":
            return cycling;
        case "dance":
            return dance;
        case 'functional':
            return Functional;
        case "hiit":
            return hiit;
        case "meditation":
            return meditation;
        case "mobility":
            return mobility;
        case "other":
            return other;
        case "pilates":
            return pilates;
        case "seniorFitness":
            return seniorFitness;
        case "sports":
            return sports;
        case "streching":
            return streching;
        case 'strengths':
            return strengths;
        case "toning":
            return toning;
        case "yoga":
            return yoga;
        default:
            return ActivityDoneImg;
    }
}

const colorSelector = (heartRate) => {
    switch (true) {
        case (heartRate > 0 && heartRate < 60):
            return "#507FB1";
        case (heartRate >= 60 && heartRate < 70):
            return "#55BA6B";
        case (heartRate >= 70 && heartRate < 80):
            return "#D6C345";
        case (heartRate >= 80 && heartRate < 90):
            return "#CF832D";
        case (heartRate >= 90):
            return "#E52353";
        default:
            // return "#ffffff";
            return "#808080";
    }
}

const textColorSelector = (heartRate) => {
    switch (true) {
        case (heartRate > 0 && heartRate < 60):
            return "#507FB1";
        case (heartRate >= 60 && heartRate < 70):
            return "#55BA6B";
        case (heartRate >= 70 && heartRate < 80):
            return "#D6C345";
        case (heartRate >= 80 && heartRate < 90):
            return "#CF832D";
        case (heartRate >= 90):
            return "#E52353";
        default:
            return "#ffffff";
    }
}

export const classSelector = (heartRate) => {
    switch (true) {
        case (heartRate > 0 && heartRate < 60):
            return 'veryLow';
        case (heartRate >= 60 && heartRate < 70):
            return "low";
        case (heartRate >= 70 && heartRate < 80):
            return "medium";
        case (heartRate >= 80 && heartRate < 90):
            return "high";
        case (heartRate >= 90):
            return "veryHigh";
        default:
            return "extraLow";
    }
}




export {
    selectIcon,
    colorSelector,
    textColorSelector,
    SVG
};