import React, { Fragment, useMemo, useState } from 'react';
import { selectIcon } from '../selector';

const FullScreen = (props) => {
    const [fullScreen, setFullScreen] = useState(document.fullscreen);
    const closeFullscreen = () => {
        setFullScreen(false);
        if (document.exitFullscreen) {
            document.exitFullscreen();
        } else if (document.webkitExitFullscreen) { /* Safari */
            document.webkitExitFullscreen();
        } else if (document.msExitFullscreen) { /* IE11 */
            document.msExitFullscreen();
        }
    }
    const openFullscreen = () => {
        const elem = document.documentElement;
        setFullScreen(true);
        if (elem.requestFullscreen) {
            elem.requestFullscreen();
        } else if (elem.webkitRequestFullscreen) { /* Safari */
            elem.webkitRequestFullscreen();
        } else if (elem.msRequestFullscreen) { /* IE11 */
            elem.msRequestFullscreen();
        }
    }
    const fullScreenUI = useMemo(() => <img className="fullScreenImg" src={selectIcon(fullScreen ? 'exit_full_screen' : 'full_screen')} onClick={fullScreen ? closeFullscreen : openFullscreen} alt='fullscreen' />,[fullScreen]);
    return (
        <Fragment>
            {fullScreenUI}
        </Fragment>
    )
}

export default FullScreen;