import React from "react";
import { CheckValue } from "../../../services/CommonFunction";
import './InputRange.scss';
import Variables from '../../../Sass/Variables.scss';

export const InputRange = ({ min, max, step, id, setMute }) => {
    return (
        <React.Fragment>
            <div className="range-slider">
                <style data="test" type="text/css"></style>
                <input className="slider" id="SliderNative1" type="range" data-vertical="true" orient="vertical" min={min} max={max} defaultValue={getDefaultValue().value} step={step}
                    onInput={e => {
                        changeVolume(e.target.value, id, false, setMute);
                        // updateSlider(e.target);
                    }} />
                <div className="range-slider__bar"></div>
                <div className="range-slider__thumb"></div>
            </div>
            {/* <input orient="vertical" type="range" className="slider" id="SliderNative" min={min} max={max} defaultValue={getDefaultValue().value} step={step} onInput={e => changeVolume(e.target.value, id, false, setMute)} /> */}
        </React.Fragment>
    )
}

export const getDefaultValue = () => {
    let value = 100;
    if (CheckValue(localStorage.getItem('setVolume'))) {
        value = parseInt(localStorage.getItem('setVolume'), 10);
        return { value: value, status: value === 0 };
    }
    return { value: value, status: value === 0 };
}
export const changeVolume = async (value, id, cond = true, setMute, callback) => {
    value = parseInt(value, 10);
    // var slider = document.getElementById("SliderNative1");
    // var style = document.querySelector('[data="test"]');
    // if (!value) {
    //     style.innerHTML = ".slider::-webkit-slider-thumb { background: " + themeColor.secondaryTextColor + "!important; }";
    // } else {
    //     style.innerHTML = ".slider::-webkit-slider-thumb { background: " + themeColor.primaryColor + "!important; }";
    // }
    const player = document.getElementById(id);
    const range = document.getElementById('SliderNative1');
    const mediaAudio = document.getElementById('mediaAudio');
    const mediaVideo = document.getElementById('mediaVideo');
    if (CheckValue(mediaVideo) && !mediaVideo.muted) {
        mediaVideo.volume = value / 100;
    }
    if (CheckValue(mediaAudio)) {
        mediaAudio.volume = value / 100;
    }
    if(CheckValue(player)) {
        if (CheckValue(cond)) {
            player.volume = value / 100;
        }
        if (CheckValue(setMute) && !CheckValue(localStorage.getItem('coachMuted'))) {
            setMute(value === 0);
            if (player.muted) {
                player.muted = false;
            }
        }
    }
    if (range) {
        if (cond) {
            range.value = value;
        }
        range.style.background = `linear-gradient(90deg, ${Variables.primaryColor1} ${value}%, ${Variables.secondaryColor1} ${value}%)`;
        localStorage.setItem('setVolume', value);
        updateSlider(range);
        if(CheckValue(callback)){
            callback(range)
        }
    }
}

export const getAudioVolume = (id) => {
    if (CheckValue(localStorage.getItem('setVolume'))) {
        return parseInt(localStorage.getItem('setVolume'), 10) === 0;
    } else {
        return true;
    }
    // const player = document.getElementById(id);
    // const audio = document.getElementById('mediaAudio');
    // if (CheckValue(localStorage.getItem('setVolume'))) {
    //     return parseInt(localStorage.getItem('setVolume'), 10) === 0;
    // } else if (CheckValue(player) && !CheckValue(localStorage.getItem('coachMuted'))) {
    //     return player.muted || parseFloat(player.volume) === 0;
    // } else if (CheckValue(audio)) {
    //     return audio.muted || parseFloat(audio.volume) === 0;
    // } else {
    //     return true;
    // }
}

const updateSlider = (element) => {
    if (element) {
        let parent = element.parentElement,
            lastValue = parent.getAttribute('data-slider-value');

        if (lastValue === element.value) {
            return; // No value change, no need to update then
        }
        parent.setAttribute('data-slider-value', element.value);
        localStorage.setItem('setVolume', element.value);
        
        let $thumb = parent.querySelector('.range-slider__thumb'),
            $bar = parent.querySelector('.range-slider__bar'),
            pct = element.value * ((116 - $thumb.clientHeight) / 116);
        $thumb.setAttribute('data-slider-value', element.value);
        // pct = element.value - $thumb.clientHeight;
        $thumb.style.bottom = `${pct}%`;
        $bar.style.height = `calc(${pct}% + ${$thumb.clientHeight / 2}px)`;
        //   $thumb.textContent = `${element.value}%`;
    }
}