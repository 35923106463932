import React, { Fragment, useEffect, useMemo, useState } from "react";
import "./Header.scss";
import {
  CheckValue,
  convertTime,
  convertTime1,
  timeUI,
} from "../../../services/CommonFunction";
import { seekVideoSubscriber } from "../../../services/subscriberService";
import { Range, getTrackBackground } from "react-range";
import { CircularProgressbarWithChildren } from "react-circular-progressbar";
import { activityType1 } from "../../../services/Constants";
import { CenterCoachProfile, Logo } from "../ChildComponentUI/ChildComponentUI";
import Variables from "../../../Sass/Variables.scss";
import IVS from "../IVS/IVS";
import { SVG, selectIcon } from "../../../components/UI/selector";
import HLS, { HlsUI } from "../HLS/HLS";
import { random } from "lodash";

const getStaticData = (text, addNew = false) => {
  let temp = activityType1.filter((res) => res.value === text);
  if (!temp.length && addNew) {
    temp.push({ icon: null, name: text, value: text, child: [] });
  }
  return temp.length ? temp[0] : {};
};

const checkMediaClipTiming = (status, currentTime, cur_exercise_data) => {
  let temp = false;
  if (CheckValue(cur_exercise_data)) {
    ["image", "audio", "video"].forEach((el) => {
      const exercise = `exercise_${el}`;
      if (CheckValue(cur_exercise_data[exercise]) && status === el) {
        const currentExercise = getData(cur_exercise_data[exercise]);
        const playAt = currentExercise[`${el}_play_at`];
        const playDuration = currentExercise[`${el}_play_duration`];
        temp = currentTime > playAt && currentTime <= playAt + playDuration;
      }
    });
  }
  return temp;
};

const getData = (data, index = 0) => (Array.isArray(data) ? data[index] : data);

export const Header = ({
  waitingRoom,
  coachData,
  coachVideoStatus,
  id,
  liveLatency,
  setData,
  canvas,
  hiddenControls,
  gymData,
  ondemand,
  onDemandControl,
  ongoing,
  showSeekBar,
  totalSeconds,
  workoutData,
  actionPerform,
  stopCoachVideo,
  low_latency_mode,
  ...props
}) => {
  const totalSecond = totalSeconds - (liveLatency + 0);
  const [coachVideo, setCoachVideo] = useState("full");
  const [mediaVideo, setMediaVideo] = useState("hide");
  const [mediaImage, setMediaImage] = useState("hide");
  const [ivsVideoShow, setIvsVideoShow] = useState(
    CheckValue(localStorage.getItem("ivsVideoShow"))
      ? localStorage.getItem("ivsVideoShow")
      : null
  );
  const [videoMediaClip, setVideoMediaClip] = useState(
    CheckValue(localStorage.getItem("videoMediaClip"))
      ? localStorage.getItem("videoMediaClip")
      : null
  );
  const { cur_segment_data, cur_exercise_data, showCountDown, currentTime } =
    checkCurrentSegment(totalSecond, ongoing.segmentDurations, waitingRoom);

  const changeValue = (coachValue, imageValue, videoValue, cond = true) => {
    if (coachVideo !== coachValue) {
      setCoachVideo(coachValue);
    }
    if (
      (cond ? CheckValue(cur_exercise_data.exercise_image) : true) &&
      mediaImage !== imageValue
    ) {
      setMediaImage(imageValue);
    }
    if (
      (cond ? CheckValue(cur_exercise_data.exercise_video) : true) &&
      mediaVideo !== videoValue
    ) {
      setMediaVideo(videoValue);
    }
  };
  const checkAudio = checkMediaClipTiming(
    "audio",
    currentTime,
    cur_exercise_data
  );
  const checkImage = checkMediaClipTiming(
    "image",
    currentTime,
    cur_exercise_data
  );
  const checkVideo = checkMediaClipTiming(
    "video",
    currentTime,
    cur_exercise_data
  );
  if (checkAudio && getData(cur_exercise_data.exercise_audio).is_coach_muted) {
    localStorage.setItem("coachMuted", true);
    const player = document.getElementById(id);
    if (CheckValue(player) && !player.muted) {
      player.muted = true;
    }
  } else if (CheckValue(localStorage.getItem("coachMuted"))) {
    localStorage.removeItem("coachMuted");
    const player = document.getElementById(id);
    if (CheckValue(player) && player.muted) {
      player.muted = false;
    }
  }
  if (
    cur_exercise_data &&
    CheckValue(cur_exercise_data.exercise_image) &&
    CheckValue(cur_exercise_data.exercise_video) &&
    checkImage &&
    checkVideo
  ) {
    if (CheckValue(videoMediaClip)) {
      const imageMediaClipPosition =
        videoMediaClip === "full" ? "pictureInPicture" : "full";
      changeValue("hide", imageMediaClipPosition, videoMediaClip, false);
    } else {
      if (
        getData(cur_exercise_data.exercise_image).image_screen_location ===
        "FULL_SCREEN"
      ) {
        changeValue("hide", "full", "pictureInPicture", false);
      } else {
        changeValue("hide", "pictureInPicture", "full", false);
      }
    }
  } else if (
    cur_exercise_data &&
    ((CheckValue(cur_exercise_data.exercise_image) && checkImage) ||
      (CheckValue(cur_exercise_data.exercise_video) && checkVideo))
  ) {
    if (CheckValue(ivsVideoShow)) {
      const mediaClipShow =
        ivsVideoShow === "full" ? "pictureInPicture" : "full";
      changeValue(ivsVideoShow, mediaClipShow, mediaClipShow);
    } else {
      if (
        (CheckValue(cur_exercise_data.exercise_image) &&
          getData(cur_exercise_data.exercise_image).image_screen_location ===
            "FULL_SCREEN" &&
          checkImage) ||
        (CheckValue(cur_exercise_data.exercise_video) &&
          getData(cur_exercise_data.exercise_video).video_screen_location ===
            "FULL_SCREEN" &&
          checkVideo)
      ) {
        changeValue("pictureInPicture", "full", "full");
      } else {
        changeValue("full", "pictureInPicture", "pictureInPicture");
      }
    }
  } else {
    changeValue("full", "hide", "hide", false);
  }

  const timeUpdate = (e, status) => {
    if (!CheckValue(cur_exercise_data[`exercise_${status}`])) {
      return null;
    }
    const exerciseData = getData(cur_exercise_data[`exercise_${status}`]);

    if (e.target.currentTime > exerciseData[`${status}_play_duration`]) {
      e.target.currentTime = 0;
      e.target.pause();
    }
  };

  const canPlay = (e, status) => {
    if (status === "video") {
      e.target.volume = 0;
      const exerciseData = getData(cur_exercise_data[`exercise_${status}`]);
      e.target.muted = exerciseData.is_video_muted;
    } else if (status === "audio" && e.target.muted) {
      e.target.volume = 0;
      e.target.muted = false;
    }
    setTimeout(() => {
      const setVolume = localStorage.getItem("setVolume");
      if (!e.target.muted) {
        if (!low_latency_mode) {
          if (CheckValue(setVolume) && setVolume / 100 !== e.target.volume) {
            e.target.volume = setVolume / 100;
          }
        } else {
          e.target.volume = 100 / 100;
        }
      }
    }, 200);
    if (CheckValue(onDemandControl) && !onDemandControl.playerPlayStatus) {
      e.target.pause();
    }
    const { play_at, duration } = getExerciseData(cur_exercise_data, status);
    if (
      CheckValue(localStorage.getItem(`${status}Now`)) ||
      (ondemand && CheckValue(onDemandControl))
    ) {
      let setTime = currentTime - play_at;
      if (CheckValue(duration) && duration && setTime > duration) {
        const loop = parseInt(setTime / duration, 10);
        setTime = setTime - duration * loop;
        setTime = parseInt(setTime, 10);
      }
      if (Math.round(e.target.currentTime) !== setTime) {
        if (CheckValue(localStorage.getItem(`${status}Now`))) {
          localStorage.removeItem(`${status}Now`);
          setTime++;
        }
        e.target.currentTime = setTime;
      }
    }
  };

  const remainingTime =
    cur_exercise_data !== undefined && Object.keys(cur_exercise_data).length
      ? cur_exercise_data.exercise_duration_in_seconds -
        (totalSecond -
          (cur_exercise_data.combinedExerciseMax -
            cur_exercise_data.exercise_duration_in_seconds))
      : 0;
  const stopCoachVideoUI = useMemo(() => (
    <span className="stopCoachVideo">
      Turn the coach's video back on from the in-class setting.
    </span>
  ));
  return (
    <React.Fragment>
      {CheckValue(onDemandControl) && onDemandControl.controls ? (
        <VideoControls {...onDemandControl} />
      ) : null}
      {stopCoachVideo ? stopCoachVideoUI : null}
      {/* {  actionPerform && !stopCoachVideo ?  (low_latency_mode?  */}
      {!ondemand &&
      !stopCoachVideo &&
      (low_latency_mode ? !coachVideoStatus : !ongoing.ivsStatus) ? (
        <div className={`${coachVideo}`}>
          <CenterCoachProfile {...coachData} />
        </div>
      ) : null}
      {!stopCoachVideo ? (
        waitingRoom || low_latency_mode ? (
          <div className={`${coachVideo}`}>
            {coachVideo === "pictureInPicture" ? (
              <div
                className="swapIcon"
                onClick={() => {
                  setIvsVideoShow("full");
                  setCoachVideo("full");
                  if (mediaVideo !== "hide") {
                    setMediaVideo("pictureInPicture");
                  }
                  if (mediaImage !== "hide") {
                    setMediaImage("pictureInPicture");
                  }
                  localStorage.setItem("ivsVideoShow", "full");
                }}
              >
                <SVG src={selectIcon("swapIcon")} />
              </div>
            ) : null}
            {canvas}
            {/* <canvas id='coach_canvas_view1' className='coachVideo' width="1920" height="1080"></canvas> */}
          </div>
        ) : (
          <IVS
            liveLatency={liveLatency}
            setData={setData}
            showSwap={coachVideo === "pictureInPicture"}
            currentData={{
              audio: cur_exercise_data
                ? getExerciseData(cur_exercise_data, "audio")
                : null,
            }}
            ivsClass={coachVideo}
            id={id}
            totalSeconds={totalSeconds}
            isOndemand={ondemand}
            URL={ongoing.playBackURL}
            ivsStatus={ongoing.ivsStatus}
            swapClick={() => {
              setIvsVideoShow("full");
              setCoachVideo("full");
              if (mediaVideo !== "hide") {
                setMediaVideo("pictureInPicture");
              }
              if (mediaImage !== "hide") {
                setMediaImage("pictureInPicture");
              }
              localStorage.setItem("ivsVideoShow", "full");
            }}
          />
        )
      ) : null}
      {/* {mediaImage !== 'hide'?<image src={} />:null} */}
      {actionPerform && !stopCoachVideo && checkAudio ? (
        <div className="mediaAudio">
          <audio
            controls
            muted
            id="mediaAudio"
            src={getData(cur_exercise_data.exercise_audio).audio_url}
            autoPlay
            loop={
              getData(cur_exercise_data.exercise_audio).is_audio_play_in_loop
            }
            onCanPlay={(e) => canPlay(e, "audio")}
            onTimeUpdate={(e) => timeUpdate(e, "audio")}
          />
        </div>
      ) : null}
      {actionPerform &&
      !stopCoachVideo &&
      checkImage &&
      mediaImage !== "hide" ? (
        <div className={mediaImage}>
          {mediaImage === "pictureInPicture" ? (
            <div
              className="swapIcon"
              onClick={() => {
                if (coachVideo === "hide") {
                  setVideoMediaClip("pictureInPicture");
                  setMediaVideo("pictureInPicture");
                  setMediaImage("full");
                  localStorage.setItem("videoMediaClip", "pictureInPicture");
                } else {
                  setIvsVideoShow("pictureInPicture");
                  setCoachVideo("pictureInPicture");
                  setMediaImage("full");
                  localStorage.setItem("ivsVideoShow", "pictureInPicture");
                }
              }}
            >
              <SVG src={selectIcon("swapIcon")} />
            </div>
          ) : null}
          <img
            src={getData(cur_exercise_data.exercise_image).image_url_original}
            alt="media_image"
          />
        </div>
      ) : null}
      {actionPerform &&
      !stopCoachVideo &&
      checkVideo &&
      mediaVideo !== "hide" ? (
        <div className={mediaVideo}>
          {mediaVideo === "pictureInPicture" ? (
            <div
              className="swapIcon"
              onClick={() => {
                if (coachVideo === "hide") {
                  setVideoMediaClip("full");
                  setMediaVideo("full");
                  setMediaImage("pictureInPicture");
                  localStorage.setItem("videoMediaClip", "full");
                } else {
                  setIvsVideoShow("pictureInPicture");
                  setCoachVideo("pictureInPicture");
                  setMediaVideo("full");
                  localStorage.setItem("ivsVideoShow", "pictureInPicture");
                }
              }}
            >
              <SVG src={selectIcon("swapIcon")} />
            </div>
          ) : null}
          {ondemand ? (
            <HLS
            currentData={{
              video: cur_exercise_data
                ? getExerciseData(cur_exercise_data, "video")
                : null,
            }}
            timeUpdate={(e) => timeUpdate(e, "video")}
            isOndemand={ondemand}
            canPlay={(e) => canPlay(e, "video")}
            loop={
              getData(cur_exercise_data.exercise_video).is_video_play_in_loop
            }
            controls={true}
            id={"mediaVideo"}
            videoSrc={getData(cur_exercise_data.exercise_video).video_url}
            />
          ) : (
            <HlsUI
              data={getExerciseData(cur_exercise_data, "video")}
              currentTime={currentTime}
              low_latency_mode={low_latency_mode}
            />
          )}
        </div>
      ) : null}
      <div className="infoBox1">
        <Logo logo_thumb={workoutData.logo_thumb} />
        <SegmentUI
          showSeekBar={showSeekBar}
          hiddenControls={hiddenControls}
          ondemand={ondemand}
          ongoing={ongoing}
          totalSecond={totalSecond}
          totalSeconds={totalSeconds}
          cur_exercise_data={cur_exercise_data}
        />
        <ActivityTime
          cond={
            showCountDown &&
            showSeekBar &&
            ongoing.segmentDurations.length &&
            totalSecond < ongoing.total_activity_duration_in_seconds &&
            CheckValue(cur_exercise_data)
          }
          remainingTime={remainingTime}
          cur_exercise_data={cur_exercise_data}
          cur_segment_data={cur_segment_data}
        />
      </div>
      {props.children}
    </React.Fragment>
  );
};

const getExerciseData = (cur_exercise_data, status) => {
  if (
    !CheckValue(cur_exercise_data) ||
    !CheckValue(cur_exercise_data[`exercise_${status}`])
  ) {
    return null;
  }
  const exerciseData = getData(cur_exercise_data[`exercise_${status}`]);
  return {
    play_at: exerciseData[`${status}_play_at`],
    duration: exerciseData[`${status}_duration`],
    play_duration: exerciseData[`${status}_play_duration`],
    exercise_id: cur_exercise_data["exercise_id"],
    [`${status}_url`]: exerciseData[`${status}_url`],
    ...exerciseData,
  };
};

export const RenderThumb = ({ props, isDragged }) => (
  <div {...props} style={{ ...props.style }}>
    <div style={{ backgroundColor: isDragged ? "yellow" : "yellow" }} />
  </div>
);
export const RenderTrack = ({ el, children, props }) => (
  <div
    onMouseDown={props.onMouseDown}
    onTouchStart={props.onTouchStart}
    style={{
      ...props.style,
      height: "12px",
      display: "flex",
      width: "100%",
    }}
  >
    <div
      ref={props.ref}
      style={{
        height: "8px",
        width: "100%",
        borderRadius: "8px",
        background: getTrackBackground({
          values: el.values,
          colors: [Variables.primaryColor1, Variables.progressBarColor],
          min: el.MIN,
          max: el.MAX,
        }),
        alignSelf: "center",
      }}
    >
      {children}
    </div>
  </div>
);

const checkCurrentSegment = (totalSeconds, segmentDurations, waitingRoom) => {
  let value = {
    cur_segment_data: undefined,
    cur_exercise_data: undefined,
    showCountDown: true,
    currentTime: 0,
  };
  if (CheckValue(segmentDurations) && !waitingRoom) {
    segmentDurations.forEach((el) => {
      const isCombinedMax = totalSeconds > el.combinedMax;
      if (
        isCombinedMax &&
        (!CheckValue(el.values) ||
          (el.values.length && el.values[0] !== el.MAX))
      ) {
        el.values = [el.MAX];
      }
      if (
        !isCombinedMax &&
        totalSeconds > el.combinedMax - el.segment_duration_in_seconds
      ) {
        el.values = [
          totalSeconds - (el.combinedMax - el.segment_duration_in_seconds) + 1,
        ];
        value.cur_segment_data = { ...el };
        value.cur_exercise_data = el.exercises.find(
          (ed) => totalSeconds <= ed.combinedExerciseMax
        );
      }
    });
  }
  if (CheckValue(value.cur_exercise_data)) {
    if (
      Array.isArray(value.cur_exercise_data) &&
      value.cur_exercise_data.length
    ) {
      value.cur_exercise_data = value.cur_exercise_data[0];
    }
    if (CheckValue(value.cur_exercise_data.video_url)) {
      value.cur_exercise_data.video_url =
        value.cur_exercise_data.video_url + `?v=${random(1, 9)}.0`;
    }
    if (CheckValue(value.cur_exercise_data.show_countdown)) {
      value.showCountDown = value.cur_exercise_data.show_countdown;
    }
    if (
      CheckValue(value.cur_exercise_data.combinedExerciseMax) &&
      CheckValue(value.cur_exercise_data.exercise_duration_in_seconds)
    ) {
      value.currentTime =
        totalSeconds -
        (value.cur_exercise_data.combinedExerciseMax -
          value.cur_exercise_data.exercise_duration_in_seconds);
    }
  }
  return value;
};

export const ActivityTime = ({
  cond,
  remainingTime,
  cur_exercise_data,
  cur_segment_data,
}) => {
  return (
    <div className="time">
      {cond ? (
        <CircularProgressbarWithChildren
          value={remainingTime}
          maxValue={cur_exercise_data.exercise_duration_in_seconds}
          strokeWidth={3}
          styles={{
            path: { stroke: Variables.primaryColor1 },
            trail: { stroke: "rgba(0,0,0,0.001)" },
          }}
        >
          <div
            className={
              convertTime(remainingTime).length > 6
                ? "newInnerCircle"
                : "innerCircle"
            }
          >
            {Object.keys(cur_segment_data).length ? (
              <p style={{ textAlign: "center" }}>
                {" "}
                {convertTime(remainingTime)}{" "}
              </p>
            ) : null}
          </div>
        </CircularProgressbarWithChildren>
      ) : null}
    </div>
  );
};

export const Hidden = ({
  STEP,
  MIN,
  MAX,
  updateState,
  updateSegmentArray,
  seg_total_width,
  segmentDurations,
  is_WorkoutBuilder_Available,
  seg_total_duration,
  values,
}) => {
  const max = seg_total_duration <= MAX ? seg_total_duration : MAX;
  const latestValue =
    values[0] <= seg_total_duration
      ? [values[0]]
      : is_WorkoutBuilder_Available
      ? seg_total_duration > max
        ? [max]
        : [seg_total_duration]
      : [seg_total_duration];
  return (
    <div
      className="hiddenSeekbar"
      style={{ opacity: is_WorkoutBuilder_Available ? "0" : "1" }}
    >
      <Range
        values={latestValue}
        step={STEP}
        min={MIN}
        max={max}
        onChange={(values) => {
          if (values < max - 2) {
            updateSegmentArray([...segmentDurations], values);
            updateState({ values, totalSeconds: values[0] });
            seekVideoSubscriber.send(values[0]);
          }
        }}
        renderTrack={({ props, children }) => (
          <div
            onMouseDown={props.onMouseDown}
            onTouchStart={props.onTouchStart}
            style={{
              ...props.style,
              height: "12px",
              display: "flex",
              width: "100%",
            }}
          >
            <div
              ref={props.ref}
              style={{
                height: "8px",
                width: "100%",
                borderRadius: "4px",
                background: getTrackBackground({
                  values: values,
                  colors: [Variables.primaryColor1, "#868686"],
                  min: MIN,
                  max: MAX,
                }),
                alignSelf: "center",
              }}
            >
              {children}
            </div>
          </div>
        )}
        renderThumb={({ props, isDragged }) => (
          <div
            {...props}
            style={{
              ...props.style,
              outline: "none",
              borderRadius: "25px",
              backgroundColor: "#F7F9F9",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              boxShadow: "0px 2px 6px #AAA",
            }}
          >
            <div
              style={{
                height: "1px",
                width: "1px",
                backgroundColor: isDragged ? "darkgrey" : "darkgrey",
              }}
            />
          </div>
        )}
      />
    </div>
  );
};

export const VideoControls = ({ videoControl, playerPlayStatus }) => {
  const videoControls = useMemo(
    () => (
      <div className="videoControls">
        <img
          src={selectIcon("seekBack")}
          onClick={() => videoControl("backward")}
          alt="backward"
        />
        <img
          src={selectIcon(playerPlayStatus ? "videoPause" : "videoPlay")}
          onClick={() => videoControl(playerPlayStatus ? "pause" : "play")}
          alt="videoStatus"
        />
        <img
          src={selectIcon("seekForward")}
          onClick={() => videoControl("forward")}
          alt="forward"
        />
      </div>
    ),
    [playerPlayStatus]
  );
  return <Fragment>{videoControls}</Fragment>;
};

const ShowExerciseNameUI = ({ show_to_participants, exercise_name }) => {
  const [show, setShow] = useState(true);
  useEffect(() => {
    if (CheckValue(show_to_participants)) {
      setShow(show_to_participants);
    } else {
      setShow(true);
    }
  }, [show_to_participants]);
  return <span className="segmentName">{exercise_name || ""} </span>;
};

const SegmentUI = ({
  showSeekBar,
  hiddenControls,
  ondemand,
  ongoing,
  totalSecond,
  totalSeconds,
  cur_exercise_data,
}) => {
  const { text, time } = convertTime1(
    ongoing.segmentDurations.length
      ? ongoing.total_activity_duration_in_seconds
      : totalSecond
  );
  return (
    <div className="rangeSlider">
      {showSeekBar ? (
        <React.Fragment>
          <div className="segmentTime">
            {ongoing.segmentDurations.length ? timeUI(totalSeconds, text) : ""}
          </div>
          {ondemand ? <Hidden {...hiddenControls} /> : null}
          <RangeSliderUI ongoing={ongoing} />
          <div
            className={
              ongoing.segmentDurations.length ? "activityTime" : "activityTime1"
            }
          >
            {time}
            <ShowExerciseNameUI {...cur_exercise_data} />
            {/* {showExerciseName &&
          totalSecond < ongoing.total_activity_duration_in_seconds &&
          CheckValue(cur_exercise_data) ? (
            <span className="segmentName">
              {cur_exercise_data.exercise_name}{" "}
            </span>
          ) : null} */}
          </div>
        </React.Fragment>
      ) : null}
    </div>
  );
};

const RangeSliderUI = ({ ongoing }) => {
  const rangeSliders = ongoing.segmentDurations
    .filter((el) => el.width)
    .map((el, index) => {
      const active = (el.values.length ? el.values[0] : 0) > 0;
      const icon = CheckValue(el.segment_icon)
        ? getStaticData(el.segment_icon, "type1").icon
        : el.segment_icon_svg_url;
      return (
        <div
          key={index}
          className={`rangeSlider_div${active ? " active" : ""}`}
          style={{ width: "calc(" + el.width + ")" }}
        >
          {CheckValue(icon) ? (
            <SVG
              className={`segment_icon${
                !CheckValue(el.segment_icon) ? " new" : ""
              }`}
              src={icon}
            />
          ) : null}
          <Range
            disabled={true}
            values={el.values}
            step={el.STEP}
            min={el.MIN}
            max={el.MAX}
            onChange={(values) => {}}
            renderTrack={({ children, props }) => (
              <div
                onMouseDown={props.onMouseDown}
                onTouchStart={props.onTouchStart}
                style={{
                  ...props.style,
                  height: "12px",
                  display: "flex",
                  width: "100%",
                }}
              >
                <div
                  ref={props.ref}
                  style={{
                    height: "8px",
                    width: "100%",
                    borderRadius: "8px",
                    background: getTrackBackground({
                      values: el.values,
                      colors: [
                        Variables.primaryColor1,
                        Variables.progressBarColor,
                      ],
                      min: el.MIN,
                      max: el.MAX,
                    }),
                    alignSelf: "center",
                  }}
                >
                  {children}
                </div>
              </div>
            )}
            renderThumb={({ props, isDragged }) => (
              <div {...props} style={{ ...props.style }}>
                <div
                  style={{ backgroundColor: isDragged ? "yellow" : "yellow" }}
                />
              </div>
            )}
          />
        </div>
      );
    });

  return <Fragment>{rangeSliders}</Fragment>;
};
